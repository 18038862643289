import * as THREE from 'three'
import mitt from 'mitt'
import { TweenLite } from 'gsap/TweenLite'

import Page from './Page'
import audio from '../audio'
import AnimationController from '../animation/AnimationController'
import settings from '../settings'
import store from '../store'
import { loadVersion } from '../actions/version'
import constants from '../actions/constants'

const ALLOWED_SYNC_DIFF = 0.15

class PlaybackController {
  frame = 0
  numFrames = 0
  time = 0
  progress = 0
  duration = 0
  fps = 60
  rate = 1
  isInitialised = false
  isPlaying = false
  isAudioEnabled = true
  isAudioFinished = false
  isAnimationFinished = false
  clock = new THREE.Clock()

  constructor() {
    Object.assign(this, mitt())

    this.updateRate = this.updateRate.bind(this)
    this.updateTime = this.updateTime.bind(this)
    this.isFinished = this.isFinished.bind(this)
    this.restart = this.restart.bind(this)
    this.handleVisibilityChange = this.handleVisibilityChange.bind(this)

    audio.on('play', () => {
      this.isAudioFinished = false
    })

    audio.on('end', () => {
      console.log('audio finished')
      this.isAudioFinished = true

      // restart animation when HNI installation
      if (window.location.pathname.replace(/\//gi, '') === 'hni') {
        setTimeout(async () => {
          // refresh textures
          await store.dispatch(loadVersion())
          // restart everything
          this.restart()
          store.dispatch({ type: constants.RESTART_APP })
        }, 5000)
      }
    })

    AnimationController.on('finished', () => {
      this.isAnimationFinished = true
      console.log('animation finished')
    })

    Page.on('visibilitychange', this.handleVisibilityChange)
  }

  isFinished() {
    return this.isAudioFinished
  }

  handleVisibilityChange(isVisible) {
    if (!isVisible && this.isPlaying) {
      this.pause({ fade: 0 })
      this.isPlaying = true
    }
    // last state has to be playing to resume...
    if (isVisible && this.isPlaying) this.play({ fade: 1.2 })
  }

  calculateDuration() {
    const shortest = Math.min(audio.duration, AnimationController.duration)
    this.duration = this.isAudioEnabled ? shortest : AnimationController.duration

    this.numFrames = Math.ceil(this.duration * this.fps)
    this.objectWithTime = shortest === audio.duration ? audio : AnimationController
  }

  updateRate() {
    if (this.isAudioEnabled && !audio.isFinished) audio.sound.rate(this.rate)
    AnimationController.setTimeScale(this.rate)
  }

  play({ fade = 0.6 } = {}) {
    return new Promise(resolve => {
      if (!AnimationController.isInitialised) return resolve()

      if (!this.clock.running) this.clock.start()

      if (this.isAudioEnabled && !audio.isFinished) {
        const cb = () => {
          AnimationController.gotoTime(audio.time)
          AnimationController.play()
          audio.off('play', cb)
        }
        audio.on('play', cb)
        audio.play()
      } else {
        AnimationController.play()
      }

      this.emit('play')
      this.isPlaying = true

      if (this.tween && this.tween.isActive()) this.tween.kill()

      if (fade > 0) {
        this.tween = TweenLite.to(this, fade, {
          rate: 1,
          volume: settings.isMuted ? 0 : 1,
          onUpdate: this.updateRate,
          onComplete: resolve,
        })
      } else {
        this.rate = 1
        this.updateRate()
        this.volume = settings.isMuted ? 0 : 1
        resolve()
      }
    })
  }

  pause({ fade = 0.6 } = {}) {
    return new Promise(resolve => {
      if (!AnimationController.isInitialised) return resolve()

      this.emit('pause')
      this.isPlaying = false

      const onComplete = () => {
        if (this.isAudioEnabled && !audio.isFinished) audio.pause()
        AnimationController.pause()
        this.rate = 0.01
        this.updateRate()
        if (this.clock.running) this.clock.stop()
        resolve()
      }

      if (this.tween && this.tween.isActive()) this.tween.kill()

      if (fade > 0) {
        this.tween = TweenLite.to(this, fade, {
          rate: 0.01,
          volume: 0,
          onUpdate: this.updateRate,
          onComplete,
        })
      } else {
        onComplete()
      }
    })
  }

  gotoFrame(frame, force = false) {
    const newTime = frame * (1 / this.fps)
    this.gotoTime(newTime, force)
  }

  gotoTime(time, force = false) {
    if (this.isAudioFinished) {
      audio.play()
    }

    if (time >= 0 && (time <= this.duration || force)) {
      if (this.isAudioEnabled) audio.gotoTime(time)
      AnimationController.gotoTime(time)
      this.isAnimationFinished = false
      this.updateTime()
      this.emit('seek', time)
    }
  }

  disableAudio() {
    this.isAudioEnabled = false
    audio.pause()
  }

  enableAudio() {
    this.isAudioEnabled = true
    audio.play()
  }

  updateTime() {
    if (!this.objectWithTime) return
    this.time = this.objectWithTime.time
    const prevFrame = this.frame
    this.frame = Math.round((AnimationController.time % AnimationController.duration) * this.fps)
    if (this.frame !== prevFrame && !this.isAnimationFinished) this.emit('frame', this.frame)
    this.progress = this.time / this.duration
  }

  tick() {
    if (this.duration === 0) this.calculateDuration()
    if (this.isAudioEnabled) audio.tick()

    const delta = this.clock.getDelta()
    AnimationController.update(delta)

    if (this.isAudioEnabled) {
      const diff = Math.abs(AnimationController.time - audio.time)
      // const timeSinceLastSync = this.lastSync ? Date.now() - this.lastSync : Number.MAX_VALUE
      if (
        diff > ALLOWED_SYNC_DIFF
        && !this.isAnimationFinished
        && !this.isAudioFinished
        && AnimationController.action.timeScale === 1
      ) {
        console.log('syncing animation to audio, diff: ', diff)
        console.log('Animation Time:', AnimationController.time)
        console.log('audio time', audio.time)
        AnimationController.gotoTime(audio.time)
        audio.gotoTime(audio.time)
        this.lastSync = Date.now()
      }
    }

    this.updateTime()
  }

  restart() {
    audio.restart()
    AnimationController.isFinished = false
    this.isAnimationFinished = false
    this.isAudioFinished = false
    this.rate = 1
    this.gotoTime(0)
    this.updateRate()
  }

  reset() {
    audio.reset()
    AnimationController.isFinished = false
    this.isAnimationFinished = false
    this.isAudioFinished = false
    this.isPlaying = false
    this.rate = 1
    this.gotoTime(0)
    this.updateRate()
  }
}

export default new PlaybackController()
