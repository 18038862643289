import React from 'react'
import SvgButton from './SvgButton'

const MuteButton = props => (
  <SvgButton id="Mute" {...props}>
    <svg viewBox="0 0 21.89 18" xmlns="http://www.w3.org/2000/svg">
      <path d="m4.27 12.1 6.38 3.4v-13l-6.38 3.4z" fill="none" />
      <path d="m1.5 6.49h1.27v5.03h-1.27z" fill="none" />
      <path
        d="m12.15 0-9.35 5h-2.8v8h2.8l9.35 5zm-10.65 6.49h1.27v5h-1.27zm9.15 9-6.38-3.39v-6.2l6.38-3.4z"
        className="color-fill"
      />
      {!props.muted && (
        <>
          <path
            d="m14.43 13.26v-.78a3.52 3.52 0 0 0 0-7v-.82a4.29 4.29 0 0 1 4.3 4.34 4.3 4.3 0 0 1 -4.3 4.3z"
            className="color-fill"
          />
          <path
            d="m14.44 11.17v-.78a1.43 1.43 0 0 0 1.43-1.39 1.44 1.44 0 0 0 -1.43-1.44v-.82a2.22 2.22 0 0 1 0 4.43z"
            className="color-fill"
          />
          <path
            d="m14.44 15.34v-.78a5.6 5.6 0 0 0 0-11.2v-.78a6.38 6.38 0 0 1 0 12.76z"
            className="color-fill"
          />
        </>
      )}
      {props.muted && (
        <g fill="none" className="color-stroke">
          <path d="m21.53 5.5-6.76 6.76" />
          <path d="m14.77 5.5 6.76 6.76" />
        </g>
      )}
    </svg>
  </SvgButton>
)

export default MuteButton
