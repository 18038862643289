import React from 'react'
import PropTypes from 'prop-types'

import Overlay from '../Overlay/Overlay'
import Translatable from '../locale/Translatable'
import LabelButton from '../Buttons/LabelButton'

const Error = ({ messageId, onClose, canRestart }) => (
  <Overlay close={!!onClose} onClose={onClose}>
    <Translatable id={messageId} />
    {canRestart && (
      <LabelButton
        label="restart"
        onTap={() => {
          window.location.reload()
        }}
      />
    )}
  </Overlay>
)

Error.propTypes = {
  messageId: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  canRestart: PropTypes.bool,
}

Error.defaultProps = {
  onClose: null,
  canRestart: true,
}

export default Error
