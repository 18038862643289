import React, { Component } from 'react'
import PropTypes from 'prop-types'
import device from 'current-device'

import './EndCredits.scss'
import PlaybackController from '../../utils/PlaybackController'
import Translateable from '../locale/Translatable'
import settings from '../../settings'
import ShareBar from '../ShareBar/ShareBar'

class EndCredits extends Component {
  state = {
    shouldRender: false,
    start: settings.times.credits[0],
    end: settings.times.credits[1],
    isInstallation: window.location.pathname.replace(/\//gi, '') === 'hni',
  }

  ref = React.createRef()

  constructor(props) {
    super(props)

    this.stopAnimation = this.stopAnimation.bind(this)
    this.updateFrame = this.updateFrame.bind(this)
    this.update = this.update.bind(this)
    this.updatePosition = this.updatePosition.bind(this)

    PlaybackController.on('frame', this.updateFrame)
  }

  componentDidUpdate(prevProps, { shouldRender: prev }) {
    const { shouldRender } = this.state
    if (this.ref.current && shouldRender && shouldRender !== prev) {
      this.raf = requestAnimationFrame(this.update)
    }
  }

  componentWillUnmount() {
    this.stopAnimation()
  }

  stopAnimation() {
    if (this.raf) cancelAnimationFrame(this.raf)
    this.raf = undefined
    this.updatePosition()
  }

  update() {
    const { frame } = PlaybackController
    const { start, end } = this.state
    const p = (frame - start) / (end - start)
    if (p <= 1) {
      this.updatePosition()
      this.raf = requestAnimationFrame(this.update)
    } else {
      this.raf = undefined
    }
  }

  updateFrame(frame) {
    const { shouldRender, start, end } = this.state
    const isRunning = frame >= start && frame <= end

    if (!shouldRender && frame >= start) this.setState({ shouldRender: true })
    if (shouldRender && frame < start) this.setState({ shouldRender: false })

    if (isRunning && !this.raf && this.ref.current) {
      this.raf = requestAnimationFrame(this.update)
    } else if (this.raf && !isRunning) {
      this.stopAnimation()
    } else if (frame >= start) {
      this.updatePosition()
    }
  }

  updatePosition() {
    if (this.ref.current) {
      const { frame } = PlaybackController
      const { start, end } = this.state
      // make sure we stay between 0 and 1
      const p = Math.min(frame > start ? (frame - start) / (end - start) : 0, 1)
      // const t = p * (2 - p) // easing
      const pos = Math.floor(-p * this.ref.current.clientHeight)
      this.ref.current.style.transform = `translate3d(0, ${pos}px, 0)`
    }
  }

  render() {
    const { shareNatively } = this.props
    const { isInstallation } = this.state
    return this.state.shouldRender ? (
      <div className="EndCredits">
        <div className="container" ref={this.ref}>
          <div className="gradient" />
          <div className="background">
            <div className="centered">
              <div className="credits-big">
                <Translateable id="credits.big" />
              </div>
              <div className="credits-page">
                <Translateable id="credits.moniker" />
              </div>
              {!isInstallation && (
                <div className="again-page">
                  <Translateable id="credits.end" />
                </div>
              )}
              {isInstallation && (
                <div className="again-page installation">https://neuhaus.world</div>
              )}
            </div>
          </div>
          {!device.desktop() && <ShareBar shareNatively={shareNatively} />}
        </div>
      </div>
    ) : null
  }
}

EndCredits.propTypes = {
  shareNatively: PropTypes.func.isRequired,
}

export default EndCredits
