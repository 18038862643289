import * as THREE from 'three'
import mitt from 'mitt'

import Camera from './Camera'
import Page from '../utils/Page'

class AnimationController {
  isInitialised = false
  isFinished = false

  constructor() {
    Object.assign(this, mitt())
    Page.on('resize', ({ aspect }) => {
      this.handleAspectChange(aspect)
    })

    this.createAnimations = this.createAnimations.bind(this)
    this.createTracks = this.createTracks.bind(this)
    this.setTimeScale = this.setTimeScale.bind(this)
    this.handleAspectChange = this.handleAspectChange.bind(this)
  }

  // needs gltf animations
  init(animations, scene) {
    if (this.isInitialised) return

    this.animations = animations
    this.mixer = new THREE.AnimationMixer(scene)
    this.createAnimations()
    this.action.timeScale = 0

    this.action.play()
    this.mixer.update(0)

    // disable looping
    this.action.clampWhenFinished = true
    this.action.setLoop(THREE.LoopOnce, 0)

    this.mixer.addEventListener('finished', () => {
      this.isFinished = true
      this.time = 0
      this.action.reset()
      this.emit('finished')
    })

    this.isInitialised = true
  }

  createAnimations() {
    // TODO: probably better to not create duplicates for ALL tracks, but just for the fov
    // that would mean three actions / more complexity
    // we should probably benchmark this for performance impact
    const portraitTracks = this.createTracks('portrait')
    const portraitClip = new THREE.AnimationClip('animations', -1, portraitTracks)
    const portraitAction = this.mixer.clipAction(portraitClip)
    portraitAction.enabled = Page.aspect <= 1

    const landscapeTracks = this.createTracks()
    const landscapeClip = new THREE.AnimationClip('animations', -1, landscapeTracks)
    const landscapeAction = this.mixer.clipAction(landscapeClip)
    landscapeAction.enabled = Page.aspect > 1 && Page.aspect <= 2.2

    const extremeLandscapeTracks = this.createTracks('extremeLandscape')
    const extremeLandscapeClip = new THREE.AnimationClip('animations', -1, extremeLandscapeTracks)
    const extremeLandscapeAction = this.mixer.clipAction(extremeLandscapeClip)
    extremeLandscapeAction.enabled = Page.aspect > 2.2

    this.duration = portraitClip.duration

    this.actions = {
      portrait: portraitAction,
      landscape: landscapeAction,
      extremeLandscape: extremeLandscapeAction,
    }

    if (Page.aspect <= 1) this.action = portraitAction
    if (Page.aspect > 1 && Page.aspect <= 2.2) this.action = landscapeAction
    if (Page.aspect > 2.2) this.action = extremeLandscapeAction
  }

  createTracks(orientation = 'landscape') {
    const tracks = [].concat(...this.animations.map(a => a.tracks)).map(t => {
      if (t.name !== 'AAA_Camera_Rotation_Around_Ball.quaternion') return t

      // CUSTOM BALL ROTATION KEYFRAMES
      const rotationZ = THREE.Math.degToRad(12314 * -1)
      const endTime = 2000
      const startRotationZ = THREE.Math.degToRad(450 * -1)

      const totalRotation = rotationZ - startRotationZ

      const requiredKeyframes = Math.abs(Math.round(totalRotation / THREE.Math.degToRad(89)))

      const startTime = t.times[t.times.length - 1]
      const deltaTime = (endTime - startTime) / requiredKeyframes

      const startX = t.times[t.values.length - 4]
      const startY = t.times[t.values.length - 3]
      const startZ = t.times[t.values.length - 2]
      const startW = t.times[t.values.length - 1]

      const startQuarternion = new THREE.Quaternion(startX, startY, startZ, startW)

      const deltaRotationZ = (rotationZ - startRotationZ) / requiredKeyframes

      const times = [...new Array(requiredKeyframes)].map((v, index) => {
        let time = startTime
        time += deltaTime * (index + 1)

        return time
      })

      const values = [...new Array(requiredKeyframes)].map((v, index) => {
        const euler = new THREE.Euler()
        euler.setFromQuaternion(startQuarternion)

        euler.z += deltaRotationZ * (index + 2)

        const quaternion = new THREE.Quaternion()
        quaternion.setFromEuler(euler)

        return [quaternion.x, quaternion.y, quaternion.z, quaternion.w]
      })

      const track = new THREE.QuaternionKeyframeTrack(
        t.name,
        [...t.times, ...times],
        [...t.values, ...[].concat(...values)],
      )
      // END CUSTOM BALL ROTATION KEYFRAMES

      return track
    })

    const farTrack = new THREE.KeyframeTrack(
      'Correction_AAA_Camera_with_Rotation.far',
      [0, 172, 173],
      [Camera.far, Camera.far, Camera.far * 5],
    )
    const nearTrack = new THREE.KeyframeTrack(
      'Correction_AAA_Camera_with_Rotation.near',
      [0, 172, 173],
      [Camera.near, Camera.near, Camera.near * 100],
    )
    const fovTrack = new THREE.KeyframeTrack(
      'Correction_AAA_Camera_with_Rotation.fov',
      [0, 3012, 4109, 6084, 6949, 7243, 9250, 11800, 14850].map(v => Math.round(v / 60)),
      [33, 33, 70, 70, 40, 70, 60, 55, 10].map(fov => {
        if (orientation === 'portrait') return fov * 1.2
        if (orientation === 'extremeLandscape') return fov * 0.8
        return fov
      }),
      THREE.InterpolateSmooth,
    )
    const partTrack = new THREE.BooleanKeyframeTrack(
      'AAA_Scene_Second_Part.visible',
      [0, 155 - 1 / 60, 156],
      [false, false, true],
    )
    tracks.push(nearTrack, farTrack, partTrack, fovTrack)
    return tracks
  }

  handleAspectChange(aspect) {
    if (this.isInitialised) {
      const { time, timeScale } = this.action
      this.action.stop()
      this.action.enabled = false
      if (aspect <= 1) {
        console.log('portrait fov')
        this.action = this.actions.portrait
      } else if (aspect > 1 && aspect <= 2.2) {
        console.log('landscape fov')
        this.action = this.actions.landscape
      } else {
        console.log('cinematic fov')
        this.action = this.actions.extremeLandscape
      }
      this.action.play()
      this.action.time = time
      this.action.timeScale = timeScale
      this.action.enabled = true
      this.mixer.update(0)
    }
  }

  play() {
    this.isFinished = false
    this.action.timeScale = 1
    this.action.play()
  }

  pause() {
    this.action.timeScale = 0
  }

  update(delta) {
    if (!this.isFinished) {
      this.mixer.update(delta)
      this.time = this.action.time
    }
  }

  gotoTime(time) {
    if (!this.action) return

    this.isFinished = false
    const delta = time - this.time

    // store old timescale
    const { timeScale } = this.action
    this.action.timeScale = 1
    this.action.play()

    this.update(delta)

    // reset to previous timeScale
    this.action.timeScale = timeScale
  }

  setTimeScale(timeScale) {
    if (!this.action) return
    this.action.timeScale = timeScale
  }
}

export default new AnimationController()
