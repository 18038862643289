import { createSelector } from 'reselect'

// TODO: is it a problem we don't wait for version or textures to be loaded
export const isTexturesLoading = createSelector(
  state => state.version.isLoadingTextures,
  state => state.version.isLoadingVersion,
  (isLoadingTextures, isLoadingVersion) => isLoadingTextures,
)

export const isAppReady = createSelector(
  state => state.app.showStartScreen,
  state => state.app.isLoadingGLTF,
  state => state.app.isLoadingAudio,
  isTexturesLoading,
  (showStartScreen, isLoadingGLTF, isLoadingAudio, isLoadingTextures) => showStartScreen && !isLoadingGLTF && !isLoadingAudio,
  // && !isLoadingTextures
)

export const hasLoadingError = createSelector(
  state => state.app.showStartScreen,
  state => state.app.gltfLoadingError,
  state => state.version.hasVersionError,
  (showStartScreen, gltfLoadingError, hasVersionError) => showStartScreen && (gltfLoadingError || hasVersionError),
)

export const getInstruction = createSelector(
  state => state.app.selectedObject,
  state => state.instructions.mapping,
  state => state.instructions.instructions,
  state => state.copy.lang,
  (object, mapping, instructions, lang) => {
    const instruction = object && mapping && instructions ? instructions[mapping[object]] : undefined
    // append translated fields to instruction
    return instruction
      ? {
        ...instruction,
        ...Object.keys(instruction).reduce((acc, key) => {
          const newKey = key.endsWith(`_${lang}`) ? key.replace(`_${lang}`, '') : key
          acc[newKey] = instruction[key]
          return acc
        }, {}),
      }
      : null
  },
)

export const getLocalisedCopy = createSelector(
  state => state.copy.lang,
  state => state.copy.translations,
  (lang, translations) => ({
    locale: lang,
    catalog: translations[lang],
  }),
)

export const isCameraEnabled = createSelector(
  state => state.app.selectedObject,
  state => state.app.isConfirming,
  (selectedObject, isConfirmingAssignment) => !!selectedObject && !isConfirmingAssignment,
)

export const isUserVersion = createSelector(
  state => state.version.name,
  state => state.user.ownsVersion,
  (versionName, userVersion) => userVersion && versionName === userVersion,
)
