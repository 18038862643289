const mapping = {
  fs_Curve196: 7,
  fs_Curve012: 1,
  fs_Curve194: 8,
  fs_Curve011: 2,
  fs_Curve008: 1,
  ds_Curve1084: 4,
  ds_Curve251: 4,
  ds_Curve1061: 4,
  ds_Curve1067: 4,
  ds_Curve1041: 4,
  ds_Curve228: 4,
  fs_Curve958: 1,
  fs_Curve007: 1,
  fs_Curve959: 1,
  fs_Curve960: 1,
  fs_Curve961: 1,
  fs_Curve962: 1,
  fs_Curve957: 1,
  fs_Curve963: 1,
  fs_Curve009: 1,
  fs_Curve263: 1,
  fs_Curve197: 8,
  fs_Curve013: 1,
  fs_Curve010: 2,
  fs_Curve193: 8,
  fs_Curve192: 1,
  fs_Curve014: 2,
  fs_Curve015: 1,
  fs_Curve191: 7,
  fs_Curve016: 2,
  fs_Curve195: 7,
  fs_Curve924: 3,
  fs_Curve027: 3,
  fs_Curve028: 3,
  ds_Curve254: 4,
  ds_Curve230: 4,
  ds_Curve253: 4,
  ds_Curve238: 4,
  ds_Curve171: 4,
  ds_Curve175: 4,
  ds_Curve1090: 4,
  ds_Curve1096: 4,
  ds_Curve160: 4,
  ds_Curve1081: 4,
  ds_Curve1076: 4,
  ds_Curve248: 4,
  ds_Curve1089: 4,
  ds_Curve231: 4,
  ds_Curve252: 4,
  ds_Curve1045: 4,
  ds_Curve250: 4,
  ds_Curve187: 4,
  fs_Curve029: 3,
  fs_Curve030: 3,
  fs_Curve031: 3,
  fs_Curve040: 3,
  ds_Curve1048: 4,
  ds_Curve1049: 4,
  ds_Curve229: 4,
  fs_Curve022: 2,
  fs_Curve023: 2,
  fs_Curve025: 2,
  fs_Curve024: 2,
  fs_Curve026: 2,
  fs_Curve032: 2,
  fs_Curve041: 2,
  ds_Curve1063: 4,
  ds_Curve1094: 4,
  ds_Curve1082: 4,
  fs_Plane023: 11,
  fs_Plane022: 16,
  fs_Plane021: 16,
  fs_Plane020: 16,
  fs_Plane016: 16,
  fs_Plane017: 16,
  fs_Plane015: 16,
  fs_Plane014: 11,
  fs_Plane011: 16,
  fs_Plane010: 16,
  fs_Plane005: 16,
  fs_Plane004: 16,
  ds_Curve1039: 4,
  ds_Curve182: 4,
  ds_Curve1074: 4,
  ds_Curve227: 4,
  ds_Curve1040: 4,
  ds_Curve181: 2,
  fs_Curve042: 3,
  fs_Curve043: 3,
  ds_Curve1071: 4,
  ds_Curve173: 4,
  ds_Curve1050: 4,
  ds_Curve1075: 4,
  ds_Curve246: 4,
  ds_Curve1059: 4,
  ds_Curve225: 4,
  ds_Curve1036: 4,
  ds_Curve1073: 4,
  ds_Curve1042: 4,
  ds_Curve1037: 4,
  ds_Curve1038: 4,
  ds_Curve1032: 4,
  ds_Curve1072: 4,
  ds_Curve1055: 4,
  ds_Curve212: 4,
  ds_Curve1052: 4,
  ds_Curve1062: 4,
  ds_Curve1034: 4,
  ds_Curve239: 4,
  ds_Curve243: 4,
  fs_Curve044: 3,
  fs_Curve045: 3,
  fs_Curve021: 2,
  fs_Curve020: 1,
  fs_Curve019: 1,
  fs_Curve017: 1,
  fs_Curve018: 1,
  fs_Curve035: 1,
  fs_Curve033: 1,
  fs_Curve036: 1,
  fs_Curve037: 1,
  fs_Curve034: 1,
  fs_Curve039: 2,
  fs_Curve059: 2,
  fs_Curve057: 2,
  ds_Curve1068: 4,
  fs_Curve058: 2,
  fs_Curve038: 1,
  fs_Curve052: 1,
  fs_Curve051: 2,
  fs_Curve060: 2,
  fs_Curve050: 2,
  fs_Curve053: 1,
  fs_Curve055: 1,
  fs_Curve049: 2,
  ds_Curve1086: 4,
  ds_Curve1091: 4,
  ds_Curve149: 4,
  ds_Curve174: 4,
  ds_Curve219: 4,
  ds_Curve1051: 4,
  ds_Curve1080: 4,
  fs_Curve062: 2,
  ds_Curve1035: 2,
  ds_Curve1078: 4,
  ds_Curve1083: 4,
  fs_Curve048: 2,
  ds_Curve1093: 4,
  ds_Curve1060: 4,
  ds_Curve1054: 4,
  ds_Curve235: 4,
  ds_Curve214: 4,
  ds_Curve1085: 4,
  ds_Curve1070: 4,
  ds_Curve1047: 4,
  ds_Curve1046: 4,
  ds_Curve158: 4,
  fs_Curve056: 2,
  ds_Curve1033: 4,
  ds_Curve156: 4,
  ds_Curve184: 4,
  ds_Curve1056: 4,
  ds_Curve237: 4,
  ds_Curve218: 4,
  fs_Curve046: 10,
  fs_Curve063: 1,
  fs_Plane000: 77,
  ds_Curve154: 4,
  fs_Curve265: 5,
  fs_Curve047: 10,
  ds_Curve1044: 4,
  fs_Curve001: 10,
  fs_Curve000: 10,
  fs_Curve264: 5,
  fs_Curve255: 5,
  fs_Curve261: 5,
  fs_Curve258: 5,
  fs_Curve259: 5,
  fs_Curve256: 5,
  fs_Curve257: 5,
  fs_Curve267: 19,
  fs_Curve: 20,
  fs_Curve271: 96,
  fs_Curve002: 22,
  fs_Curve268: 36,
  fs_Curve269: 32,
  fs_Curve273: 21,
  fs_Curve276: 24,
  ds_Curve402: 11,
  ds_Curve373: 11,
  ds_Curve400: 11,
  ds_Curve396: 11,
  ds_Plane060: 11,
  ds_Curve395: 26,
  ds_Curve393: 26,
  Plane: 11,
  ds_Curve030: 100,
  ds_Curve378: 100,
  ds_Plane000: 100,
  ds_Curve005: 40,
  ds_Curve029: 100,
  ds_Curve009: 100,
  ds_Ebene_0_6001: 25,
  ds_Curve003: 25,
  ds_Curve023: 25,
  ds_Curve000: 25,
  ds_Curve039: 25,
  ds_Ebene_0005: 25,
  ds_Curve024: 25,
  ds_Schritt1276: 25,
  ds_Ebene_0_4001: 25,
  ds_Ebene_0003: 25,
  ds_Curve016: 16,
  ds_Curve026: 16,
  ds_Curve025: 16,
  ds_Curve1066: 4,
  ds_Curve163: 4,
  ds_Curve224: 4,
  ds_Curve157: 4,
  ds_Curve1079: 4,
  ds_Curve1065: 4,
  ds_Curve159: 4,
  ds_Curve169: 4,
  ds_Curve166: 4,
  ds_Curve1057: 4,
  ds_Curve1088: 4,
  fs_Curve061: 4,
  ds_Curve167: 4,
  ds_Curve1058: 4,
  ds_Curve1064: 4,
  ds_Curve153: 4,
  ds_Curve1053: 4,
  ds_Curve177: 4,
  ds_Curve150: 4,
  ds_Curve1077: 4,
  ds_Curve1095: 4,
  fs_Plane003: 11,
  fs_Plane006: 11,
  fs_Plane009: 11,
  fs_Plane012: 11,
  ds_Curve055: 18,
  ds_Curve056: 18,
  ds_Curve1099: 18,
  ds_Curve007: 11,
  Plane001: 32,
  Plane002: 64,
  Plane003: 42,
  ds_Curve004: 11,
  ds_Curve216: 4,
  ds_Curve1069: 4,
  fs_Plane024: 11,
  fs_Plane013: 11,
  fs_Plane008: 11,
  ds_Curve1107: 83,
  fs_Plane019: 11,
  ds_Curve1105: 87,
  ds_Curve1106: 45,
  ds_Curve006: 87,
  ds_Curve1109: 63,
  ds_Curve010: 100,
  ds_Curve1100: 63,
  ds_Plane008: 87,
  ds_Plane066: 87,
  ds_Curve015: 87,
  ds_Plane002: 87,
  ds_Plane001: 87,
  ds_Curve013: 87,
  ds_Curve057: 87,
  ds_Curve052: 87,
  ds_Curve019: 63,
  ds_Plane069: 87,
  ds_Plane006: 87,
  ds_Curve020: 63,
  ds_Curve014: 16,
  ds_Curve027: 16,
  ds_Curve021: 72,
  ds_Plane007: 87,
  ds_Plane068: 87,
  ds_Curve011: 87,
  ds_Plane003: 87,
  ds_Curve058: 87,
  ds_Curve1112: 13,
  ds_Curve1102: 17,
  ds_Curve060: 11,
  ds_Plane005: 93,
  ds_Curve035: 11,
  ds_Curve1111: 36,
  ds_Curve037: 11,
  ds_Curve062: 11,
  ds_Curve041: 11,
  ds_Curve036: 11,
  ds_Curve042: 11,
  ds_Curve048: 16,
  ds_Curve061: 16,
  ds_Curve063: 16,
  ds_Curve1101: 16,
  ds_Curve1104: 13,
  ds_Curve1103: 8,
  ds_Curve033: 11,
  ds_Curve040: 18,
  ds_Curve043: 18,
  ds_Plane010: 15,
  ds_Curve049: 16,
  ds_Plane063: 93,
  ds_Curve001: 91,
  ds_Curve1097: 16,
  ds_Curve050: 16,
  ds_Curve051: 16,
  ds_Curve377: 16,
  ds_Curve1110: 14,
  ds_Ebene_0_2001: 25,
  ds_Schritt1263: 25,
  ds_Schritt1481: 25,
  ds_Schritt1357: 25,
  ds_Schritt1442: 25,
  ds_Schritt1465: 25,
  ds_Schritt1392: 25,
  ds_Ebene_0_5001: 25,
  ds_Schritt1403: 25,
  ds_Schritt1421: 25,
  ds_Schritt1418: 25,
  ds_Schritt1311: 25,
  ds_Schritt1329: 25,
  ds_Schritt1456: 25,
  ds_Schritt1388: 25,
  ds_Schritt1347: 25,
  ds_Schritt1494: 91,
  ds_Schritt1487: 34,
  ds_Schritt1419: 93,
  ds_Schritt1337: 91,
  ds_Schritt1387: 93,
  ds_Schritt1468: 91,
  ds_Schritt1332: 25,
  ds_Schritt1281: 93,
  ds_Schritt1393: 93,
  ds_Schritt1352: 93,
  ds_Schritt1452: 93,
  ds_Schritt1479: 93,
  ds_Schritt1333: 93,
  ds_Schritt1389: 25,
  ds_Schritt1472: 25,
  ds_Schritt1258: 25,
  ds_Schritt1423: 25,
  ds_Schritt1282: 90,
  ds_Schritt1477: 89,
  ds_Schritt1471: 88,
  ds_Schritt1433: 87,
  ds_Schritt1361: 86,
  ds_Schritt1289: 81,
  ds_Schritt1425: 81,
  ds_Schritt1482: 81,
  ds_Schritt1434: 81,
  ds_Loft22: 76,
  fs_Curve903: 98,
  fs_Curve901: 69,
  fs_Curve902: 69,
  fs_Curve899: 98,
  fs_Curve920: 91,
  fs_Curve900: 73,
  fs_Curve904: 69,
  fs_Curve905: 72,
  fs_Curve906: 71,
  fs_Curve911: 99,
  fs_Curve918: 71,
  fs_Curve917: 99,
  fs_Curve914: 99,
  fs_Curve915: 71,
  fs_Curve913: 98,
  fs_Curve912: 99,
  fs_Curve1022: 14,
  fs_Curve898: 102,
  fs_Plane033: 13,
  fs_Plane032: 13,
  fs_Plane031: 13,
  fs_Plane030: 13,
  fs_Plane029: 13,
  fs_Plane028: 13,
  fs_Plane027: 13,
  fs_Plane026: 13,
  ds_Loft12: 76,
  ds_Loft3: 76,
  ds_Loft15: 38,
  ds_Loft10: 38,
  ds_Loft4: 38,
  ds_Loft13: 38,
  ds_Loft9: 76,
  ds_Loft23: 38,
  ds_Loft20: 76,
  ds_Loft19: 38,
  ds_Loft8: 38,
  ds_Loft1: 76,
  ds_Loft11: 38,
  ds_Loft16: 38,
  ds_Loft53: 76,
  ds_Loft52: 38,
  ds_Loft32: 76,
  ds_Loft18: 38,
  ds_Loft51: 76,
  ds_Loft55: 38,
  ds_Loft67: 76,
  ds_Loft17: 76,
  ds_Loft28: 38,
  ds_Loft2: 76,
  ds_Loft5: 76,
  ds_Loft71: 38,
  ds_Loft019: 76,
  ds_Loft63: 76,
  ds_Loft58: 38,
  fs_Loft57: 76,
  ds_Loft70: 76,
  ds_Loft30: 76,
  ds_Loft21: 38,
  ds_Loft7: 76,
  ds_Loft14: 76,
  ds_Loft6: 38,
  ds_Loft: 76,
  ds_Loft37: 38,
  ds_Loft40: 76,
  ds_Loft65: 38,
  ds_Loft33: 38,
  ds_Loft59: 76,
  ds_Loft64: 38,
  ds_Loft25: 76,
  ds_Loft26: 38,
  ds_Loft31: 38,
  ds_Loft69: 76,
  ds_Loft54: 76,
  ds_Loft000: 38,
  ds_Loft27: 76,
  ds_Loft62: 38,
  ds_Loft35: 76,
  ds_Loft41: 38,
  ds_Loft61: 76,
  ds_Loft43: 38,
  ds_Loft38: 76,
  ds_Loft60: 76,
  ds_Loft66: 38,
  ds_Loft56: 38,
  ds_Loft003: 76,
  ds_Loft037: 76,
  ds_Loft012: 38,
  ds_Loft015: 76,
  fs_Loft005: 38,
  ds_Loft002: 38,
  ds_Loft007: 38,
  ds_Loft48: 76,
  ds_Loft50: 38,
  ds_Loft47: 38,
  ds_Loft39: 38,
  ds_Loft45: 76,
  fs_Curve511: 26,
  fs_Curve520: 26,
  fs_Curve535: 26,
  fs_Curve515: 26,
  fs_Curve533: 26,
  fs_Curve496: 26,
  fs_Curve514: 26,
  fs_Curve497: 27,
  fs_Curve522: 27,
  fs_Curve508: 27,
  fs_Curve505: 27,
  fs_Curve534: 27,
  fs_Curve519: 27,
  fs_Curve495: 27,
  fs_Curve532: 27,
  fs_Curve506: 27,
  fs_Curve502: 27,
  fs_Curve510: 27,
  fs_Curve524: 26,
  fs_Curve507: 28,
  fs_Curve521: 28,
  fs_Curve518: 28,
  fs_Curve516: 28,
  fs_Curve526: 28,
  fs_Curve500: 28,
  fs_Curve531: 28,
  fs_Curve529: 26,
  fs_Curve513: 27,
  fs_Curve494: 28,
  fs_Curve527: 28,
  fs_Curve509: 26,
  fs_Curve512: 26,
  fs_Curve499: 28,
  fs_Curve525: 28,
  fs_Curve498: 27,
  fs_Curve523: 26,
  fs_Curve503: 28,
  fs_Curve517: 26,
  fs_Curve530: 28,
  fs_Curve501: 28,
  fs_Curve528: 28,
  ds_Schritt1491: 93,
  ds_Schritt1257: 93,
  ds_Schritt1349: 93,
  ds_Schritt1339: 93,
  ds_Schritt1303: 93,
  ds_Schritt1335: 93,
  ds_Schritt1291: 93,
  ds_Schritt1441: 93,
  ds_Schritt1345: 93,
  ds_Schritt1414: 93,
  ds_Schritt1278: 81,
  ds_Schritt1313: 93,
  ds_Schritt1405: 93,
  ds_Schritt1294: 93,
  ds_Schritt1268: 93,
  ds_Schritt1431: 93,
  ds_Schritt1309: 93,
  ds_Schritt1272: 81,
  ds_Schritt1397: 93,
  ds_Schritt1495: 93,
  ds_Schritt1374: 93,
  ds_Schritt1270: 81,
  ds_Schritt1492: 91,
  ds_Schritt1399: 93,
  ds_Schritt1449: 93,
  ds_Schritt1408: 93,
  ds_Schritt1470: 93,
  ds_Schritt1453: 93,
  ds_Schritt1395: 93,
  ds_Schritt1390: 93,
  ds_Schritt1438: 93,
  ds_Schritt1422: 93,
  ds_Schritt1355: 93,
  ds_Schritt1394: 93,
  ds_Schritt1286: 93,
  ds_Schritt1275: 93,
  ds_Schritt1297: 93,
  ds_Schritt1279: 93,
  ds_Schritt1450: 93,
  ds_Schritt1363: 93,
  ds_Schritt1446: 93,
  ds_Schritt1429: 93,
  ds_Schritt1376: 93,
  ds_Schritt1451: 93,
  ds_Schritt1287: 93,
  ds_Schritt1380: 93,
  ds_Schritt1317: 93,
  ds_Schritt1341: 93,
  ds_Schritt1304: 93,
  ds_Schritt1338: 93,
  ds_Schritt1384: 93,
  ds_Schritt1356: 93,
  ds_Schritt1401: 93,
  ds_Schritt1336: 93,
  ds_Schritt1467: 81,
  ds_Schritt1432: 93,
  ds_Schritt1334: 93,
  ds_Schritt1365: 93,
  ds_Schritt1267: 93,
  ds_Schritt1435: 91,
  ds_Schritt1277: 93,
  ds_Schritt1273: 93,
  ds_Schritt1264: 93,
  ds_Schritt1371: 91,
  ds_Schritt1484: 93,
  ds_Schritt1447: 93,
  ds_Schritt1343: 93,
  ds_Schritt1454: 93,
  ds_Schritt1342: 93,
  ds_Schritt1262: 91,
  ds_Schritt1489: 91,
  ds_Schritt1496: 93,
  ds_Schritt1448: 93,
  ds_Schritt1411: 93,
  ds_Schritt1261: 93,
  ds_Schritt1455: 91,
  ds_Schritt1426: 93,
  ds_Schritt1502: 93,
  ds_Schritt1346: 91,
  ds_Schritt1382: 93,
  ds_Schritt1269: 93,
  ds_Schritt1324: 93,
  ds_Schritt1326: 93,
  ds_Schritt1319: 93,
  ds_Schritt1428: 93,
  ds_Schritt1321: 91,
  ds_Schritt1385: 93,
  ds_Schritt1350: 91,
  ds_Schritt1410: 93,
  ds_Schritt1420: 93,
  ds_Schritt1378: 93,
  ds_Schritt1476: 93,
  ds_Schritt1366: 93,
  ds_Schritt1285: 93,
  ds_Schritt1271: 93,
  ds_Schritt1362: 91,
  ds_Schritt1312: 93,
  ds_Schritt1348: 93,
  ds_Schritt1331: 93,
  ds_Schritt1315: 93,
  ds_Schritt1328: 93,
  ds_Schritt1474: 93,
  ds_Schritt1284: 93,
  ds_Schritt1359: 93,
  ds_Schritt1323: 93,
  ds_Schritt1316: 93,
  ds_Schritt1354: 93,
  ds_Schritt1266: 93,
  ds_Schritt1402: 93,
  ds_Schritt1415: 93,
  ds_Schritt1253: 93,
  ds_Schritt1407: 93,
  ds_Schritt1439: 93,
  ds_Schritt1254: 93,
  ds_Schritt1372: 93,
  ds_Schritt1458: 93,
  fs_Curve908: 102,
  fs_Curve909: 101,
  fs_Curve897: 101,
  fs_Curve916: 100,
  fs_Curve910: 30,
  fs_Curve919: 99,
  fs_Curve779: 87,
  fs_Curve758: 98,
  fs_Curve1010: 72,
  fs_Curve978: 73,
  fs_Curve996: 73,
  fs_Curve1017: 98,
  fs_Curve1004: 98,
  fs_Curve992: 73,
  fs_Curve974: 73,
  Curve972: 96,
  fs_Curve826: 29,
  fs_Curve807: 66,
  fs_Curve828: 29,
  fs_Curve805: 29,
  fs_Curve1018: 75,
  fs_Curve785: 75,
  fs_Curve810: 75,
  fs_Curve985: 73,
  fs_Curve797: 29,
  fs_Curve782: 75,
  fs_Curve1008: 98,
  fs_Curve833: 75,
  fs_Curve808: 66,
  fs_Curve980: 72,
  fs_Curve1014: 98,
  fs_Curve965: 98,
  fs_Curve1016: 72,
  fs_Curve786: 73,
  fs_Curve975: 73,
  fs_Curve792: 29,
  fs_Curve1103: 29,
  fs_Curve788: 66,
  fs_Curve1102: 66,
  fs_Curve1026: 66,
  fs_Curve842: 29,
  fs_Curve819: 66,
  fs_Curve802: 75,
  fs_Curve825: 75,
  fs_Curve1006: 29,
  fs_Curve834: 29,
  fs_Curve791: 29,
  fs_Curve789: 66,
  fs_Curve809: 66,
  fs_Curve800: 66,
  fs_Curve814: 66,
  fs_Curve837: 98,
  fs_Curve823: 75,
  fs_Curve790: 29,
  fs_Curve822: 75,
  fs_Curve783: 75,
  fs_Curve799: 66,
  fs_Curve843: 75,
  fs_Curve820: 75,
  fs_Curve844: 75,
  fs_Curve1100: 75,
  fs_Curve841: 29,
  fs_Curve818: 75,
  fs_Curve806: 66,
  fs_Curve827: 29,
  fs_Curve1009: 66,
  fs_Curve829: 66,
  fs_Curve804: 75,
  fs_Curve839: 66,
  fs_Curve816: 29,
  fs_Curve832: 29,
  fs_Curve811: 66,
  fs_Curve817: 29,
  fs_Curve840: 66,
  fs_Curve831: 29,
  fs_Curve1099: 75,
  fs_Curve781: 66,
  fs_Curve798: 75,
  Curve794: 66,
  fs_Curve813: 66,
  fs_Curve836: 29,
  fs_Curve835: 29,
  fs_Curve803: 66,
  ds_Loft45_2_2000: 89,
  ds_Loft31_142_2000: 64,
  ds_Loft22_66_1000: 49,
  ds_Loft28_97_1000: 39,
  ds_Loft48_78_1000: 53,
  ds_Loft25_4_2000: 65,
  ds_Loft31_64001: 59,
  ds_Loft39_37_1000: 12,
  ds_Loft11_27_2002: 60,
  ds_Loft22_41_1002: 61,
  ds_Loft48_0_1002: 55,
  ds_Loft25_167000: 32,
  ds_Loft8_65_2000: 90,
  ds_Loft11_61_2000: 40,
  ds_Loft14_77_2000: 87,
  ds_Loft8_65_2002: 56,
  ds_Loft30_5_1000: 57,
  ds_Loft17_55_2000: 78,
  ds_Loft45_40_2000: 84,
  ds_Loft29_129_1000: 92,
  ds_Loft32_119_2002: 61,
  ds_Loft31_63_2001: 23,
  ds_Loft20_130_1000: 79,
  ds_Loft31_64_2001: 77,
  ds_Loft16_52_1002: 23,
  ds_Loft31_142_2001: 40,
  ds_Loft28_62_2001: 60,
  ds_Loft33_113_1002: 6,
  ds_Loft11_126_1000: 63,
  ds_Loft29_171_1001: 68,
  ds_Loft25_133_1002: 99,
  ds_Loft9_137_2001: 45,
  ds_Loft24_117_2001: 29,
  ds_Loft1_175_1001: 59,
  ds_Loft3_98_2001: 91,
  ds_Loft28_92_1002: 92,
  ds_Loft8_65_2001: 92,
  ds_Loft39_122_2001: 42,
  ds_Loft19_49_1002: 26,
  ds_Loft35_101_2001: 42,
  ds_Loft20_130_2001: 17,
  ds_Loft28_150_1001: 89,
  ds_Loft37_149_1001: 51,
  ds_Loft32_119_2001: 51,
  ds_Loft11_108_1002: 14,
  ds_Loft36_131_2001: 70,
  ds_Loft38_132_1002: 89,
  ds_Loft34_67_2001: 46,
  ds_Loft33_141_2001: 86,
  ds_Loft33_83002: 86,
  ds_Loft27_81_2001: 92,
  ds_Loft12_114_1000: 74,
  ds_Loft15_151_1001: 54,
  ds_Loft33_113_2001: 54,
  ds_Loft13_47_2001: 56,
  ds_Loft14_124_2001: 41,
  ds_Loft28_92_2001: 39,
  ds_Loft3_172_1001: 63,
  ds_Loft22_153_1001: 39,
  ds_Loft38_79_2001: 39,
  ds_Loft37_94_2001: 39,
  ds_Loft18_106_2001: 12,
  ds_Loft22_66_2001: 39,
  ds_Loft11_27_2001: 42,
  ds_Loft30_12_1002: 49,
  ds_Loft38_75_1002: 42,
  ds_Loft2_30_2001: 42,
  ds_Loft32_22_2001: 86,
  ds_Loft29_129_1001: 58,
  ds_Loft25_60_2001: 64,
  ds_Loft26_13_1002: 65,
  ds_Loft10_54_1002: 65,
  ds_Loft17_55_2001: 65,
  ds_Loft39_37_1002: 65,
  ds_Loft38_75_1001: 62,
  ds_Loft17_69_2001: 65,
  ds_Loft24_25_2001: 47,
  ds_Loft37_90_1002: 47,
  ds_Loft11_95_1002: 47,
  ds_Loft25_1_2001: 47,
  ds_Loft2_44_2001: 74,
  ds_Loft28_74_1002: 47,
  ds_Loft26_128_1002: 22,
  ds_Loft40_134_1002: 47,
  ds_Loft25_180_1001: 47,
  ds_Loft45_40_2001: 47,
  ds_Loft11_145_2001: 47,
  ds_Loft37_90_2001: 47,
  ds_Loft4_144_2001: 33,
  ds_Loft8_152_1001: 12,
  ds_Loft31_170_1000: 41,
  ds_Loft20_130_1002: 41,
  ds_Loft37_94_1002: 41,
  ds_Loft11_61_2001: 41,
  ds_Loft15_151_1000: 38,
  ds_Loft28_74_2001: 41,
  ds_Loft21_102_2001: 91,
  ds_Loft31_170_1001: 91,
  ds_Loft11_108_2001: 37,
  ds_Loft_110_2001: 37,
  ds_Loft41_82_2001: 37,
  ds_Loft32_135_1002: 37,
  ds_Loft32_59_2001: 37,
  ds_Loft47_71_1002: 37,
  ds_Loft17_69_1002: 37,
  ds_Loft40_32_1002: 37,
  ds_Loft16_10_2001: 88,
  ds_Loft24_35_2001: 37,
  ds_Loft30_34_1002: 40,
  ds_Loft32_36_1002: 40,
  ds_Loft19_89_1002: 40,
  ds_Loft45_2_2001: 40,
  ds_Loft10_54_2001: 40,
  ds_Loft14_77_1000: 91,
  ds_Loft30_39_1002: 40,
  ds_Loft39_147_1001: 18,
  ds_Loft26_51_1002: 78,
  ds_Loft30_12_2001: 44,
  ds_Loft39_76_1002: 44,
  ds_Loft23_136_2001: 44,
  ds_Loft6_72_1002: 44,
  ds_Loft46_105_2001: 44,
  ds_Loft30_5_1002: 44,
  ds_Loft39_112_2000: 48,
  ds_Loft28_97_1002: 44,
  ds_Loft24_29_2001: 44,
  ds_Loft28_97_2001: 64,
  ds_Loft26_13_2001: 64,
  ds_Loft49_42_2001: 64,
  ds_Loft_88_2001: 64,
  ds_Loft38_33_1002: 64,
  ds_Loft24_25002: 64,
  ds_Loft27_53_1002: 64,
  ds_Loft28_21_2000: 86,
  ds_Loft22_153_1000: 88,
  ds_Loft14_124000: 37,
  ds_Loft32_22_1002: 58,
  ds_Loft27_53_2001: 58,
  ds_Loft39_76_1000: 33,
  ds_Loft32_119_1000: 83,
  ds_Loft39_122_1000: 42,
  ds_Loft14_45_1002: 61,
  ds_Loft38_163000: 51,
  ds_Loft40_134_1000: 46,
  ds_Loft33_38_1002: 42,
  ds_Loft32_9_1002: 17,
  ds_Loft9_24_2001: 17,
  ds_Loft17_55_1002: 17,
  ds_Loft20_18_2001: 17,
  ds_Loft4_144_1000: 55,
  ds_Loft26_57_1002: 17,
  ds_Loft_93_1000: 50,
  ds_Loft31_64_1002: 21,
  ds_Loft25_4_1002: 14,
  ds_Loft16_10_1002: 25,
  ds_Loft10_6_1002: 20,
  ds_Loft23_16_2001: 64,
  ds_Loft24_35_1002: 27,
  ds_Loft24_179_1000: 35,
  ds_Loft36_104_1000: 47,
  ds_Loft3_172_1000: 36,
  ds_Loft38_132_1000: 17,
  ds_Loft14_124002: 6,
  ds_Loft39_122_1002: 14,
  ds_Loft32_166_1001: 30,
  ds_Loft18_177_1001: 54,
  ds_Loft25_158_1001: 54,
  ds_Loft39_112_2001: 14,
  ds_Loft25_167_1001: 30,
  ds_Loft12_114_2001: 90,
  ds_Loft25_1_1002: 89,
  ds_Loft38_168_1000: 52,
  ds_Loft40_140_1002: 14,
  ds_Loft29_129_1002: 89,
  ds_Loft18_106_1002: 54,
  ds_Loft31_142_1002: 70,
  ds_Loft24_179_1001: 70,
  ds_Loft27_86_1002: 19,
  ds_Loft31_142002: 97,
  ds_Loft21_164_1001: 60,
  ds_Loft44_127_1002: 60,
  ds_Loft38_168_1001: 60,
  ds_Loft26_103_2001: 60,
  ds_Loft39_112_1002: 32,
  ds_Loft39_155_1001: 32,
  ds_Loft35_169_1001: 100,
  ds_Loft31_63002: 100,
  ds_Loft42_85_2001: 100,
  ds_Loft31_64002: 100,
  ds_Loft9_154_1001: 100,
  ds_Loft19_49_2001: 100,
  ds_Loft36_104000: 54,
  ds_Loft44_127_1000: 34,
  ds_Loft49_42_1002: 19,
  ds_Loft17_55002: 24,
  ds_Loft31_63_1002: 31,
  ds_Plane011: 63,
  ds_Curve018: 87,
  ds_Loft_20_2001: 2,
  ds_Loft28_21_2001: 1,
  ds_Loft7_23_2001: 17,
  ds_Loft9_24_1002: 21,
  ds_Loft25_4_2001: 20,
  ds_Loft42_8_1002: 21,
  ds_Loft14_77_2001: 56,
  ds_Loft8_65_1002: 76,
  ds_Loft11_27_1002: 98,
  ds_Loft24_25_1002: 97,
  ds_Loft9_7_1002: 30,
  ds_Loft22_41_2001: 47,
  ds_Loft28_21_1002: 51,
  ds_Loft11_61_1002: 38,
  ds_Loft32_59_1002: 57,
  ds_Loft30_12002: 51,
  ds_Loft22_66_1002: 38,
  ds_Loft26_57_2001: 34,
  ds_Loft13_47_1002: 49,
  ds_Loft2_44_1002: 36,
  ds_Loft26_13002: 47,
  ds_Loft33_38_2001: 25,
  ds_Loft30_34002: 49,
  ds_Loft21_46_1002: 10,
  ds_Loft27_81_1002: 24,
  ds_Loft17_19_2001: 37,
  ds_Loft7_23_1002: 28,
  ds_Loft32_9_2001: 19,
  ds_Loft23_3_2001: 43,
  ds_Loft45_40_1002: 41,
  ds_Loft48_0_2001: 34,
  ds_Loft23_17_2001: 70,
  ds_Loft8_28_2001: 95,
  ds_Loft17_19_1002: 44,
  ds_Loft23_16_1002: 40,
  ds_Loft6_72_2001: 34,
  ds_Loft25_60_1002: 12,
  ds_Loft20_18_1002: 86,
  ds_Loft47_71_2001: 61,
  ds_Loft45_2_1002: 40,
  ds_Loft17_19002: 3,
  ds_Loft32_22002: 4,
  ds_Loft2_30_1002: 54,
  ds_Loft9_14_1002: 30,
  ds_Loft19_89002: 27,
  ds_Loft37_90002: 8,
  ds_Loft6_111002: 33,
  ds_Loft36_58_1002: 37,
  ds_Loft_20_1002: 24,
  ds_Loft17_69002: 41,
  ds_Loft36_15_1002: 40,
  ds_Loft23_50_2001: 87,
  ds_Loft_56_1002: 84,
  ds_Loft29_129002: 72,
  ds_Loft14_77_1002: 65,
  ds_Loft38_79_1002: 25,
  ds_Loft_110002: 51,
  ds_Loft37_116_1002: 43,
  ds_Loft12_114_1002: 45,
  ds_Loft8_28_1002: 53,
  ds_Loft35_70_1002: 36,
  ds_Loft36_131002: 32,
  ds_Loft3_98_1002: 71,
  ds_Loft39_147002: 52,
  ds_Loft40_84_2001: 55,
  ds_Loft26_51_2001: 51,
  ds_Loft25_48_1002: 56,
  ds_Loft11_27002: 52,
  ds_Loft23_50_1002: 39,
  ds_Loft21_46002: 34,
  ds_Loft14_45002: 95,
  ds_Loft21_46_2001: 76,
  ds_Loft_11_1002: 88,
  ds_Loft43_91_1002: 86,
  ds_Loft18_106002: 95,
  ds_Loft_110_1002: 95,
  ds_Loft2_138_2001: 11,
  ds_Loft_93_1002: 12,
  ds_Loft36_87_2001: 50,
  ds_Loft33_156_1001: 18,
  ds_Loft36_104_2001: 51,
  ds_Loft14_45_2001: 10,
  ds_Loft45_40002: 2,
  ds_Loft49_42002: 62,
  ds_Loft40_84002: 34,
  ds_Loft34_67002: 37,
  ds_Loft38_163002: 37,
  ds_Loft6_111_1002: 7,
  ds_Loft43_43002: 68,
  ds_Loft13_47002: 44,
  ds_Loft37_68002: 36,
  ds_Loft25_167002: 36,
  ds_Loft23_136_1002: 6,
  ds_Loft36_131_1002: 55,
  ds_Loft22_66002: 53,
  ds_Loft11_108002: 33,
  ds_Loft11_145002: 45,
  ds_Loft43_107_1002: 69,
  ds_Loft26_103002: 6,
  ds_Loft39_122002: 70,
  ds_Loft26_161002: 91,
  ds_Loft41_176_1001: 40,
  ds_Loft9_178_1001: 73,
  ds_Loft19_109_2001: 7,
  ds_Loft9_137_1002: 71,
  ds_Loft43_146_1001: 82,
  ds_Loft35_157_1001: 44,
  ds_Loft11_126002: 100,
  ds_Loft23_99_2001: 60,
  ds_Loft37_162_1001: 59,
  ds_Loft24_29002: 46,
  ds_Loft8_65002: 38,
  ds_Loft32_166002: 43,
  ds_Loft25_48_2001: 52,
  ds_Loft28_62002: 21,
  ds_Loft35_31_1002: 47,
  ds_Loft39_76_2001: 71,
  ds_Loft48_78_1002: 6,
  ds_Loft37_94002: 54,
  ds_Loft40_84_1002: 57,
  ds_Loft35_101_1002: 37,
  fs_Curve801: 29,
  fs_Curve1015: 98,
  fs_Curve003: 2,
  fs_Curve004: 2,
  fs_Curve1104: 1,
  fs_Curve005: 100,
  fs_Curve006: 2,
  ds_Plane009: 80,
  ds_Curve053: 83,
  ds_Loft005: 32,
  fs_Curve796: 29,
  fs_Curve824: 75,
  fs_Curve830: 66,
  ds_Curve038: 11,
  ds_Schritt1302: 7,
  ds_Schritt1358: 93,
  fs_Curve821: 66,
  fs_Curve812: 29,
  ds_Loft32_119_1002: 57,
  ds_Curve1043: 4,
  ds_Curve170: 4,
  ds_Loft1_159_1001: 102,
  ds_Loft2_44002: 84,
  ds_Loft4_144002: 40,
  ds_Loft9_24002: 33,
  ds_Loft11_95_2001: 47,
  ds_Loft12_143_1002: 17,
  ds_Loft19_109002: 35,
  ds_Loft20_18002: 94,
  ds_Loft21_173002: 54,
  ds_Loft23_99_1002: 79,
  ds_Loft24_117_1002: 42,
  ds_Loft24_35002: 59,
  ds_Loft25_133002: 60,
  ds_Loft25_158002: 89,
  ds_Loft29_171002: 58,
  ds_Loft35_169002: 50,
  ds_Loft36_58_2001: 53,
  ds_Loft36_58002: 83,
  ds_Loft39_37_2001: 65,
  ds_Loft43_43_2001: 38,
  ds_Loft_56002: 48,
}

export default mapping
