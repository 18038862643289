import constants from '../actions/constants'
import settings from '../settings'
import getAddressBarHeight from '../utils/getAddressBarHeight'

const initialState = {
  isLoadingAudio: true,
  isConfirming: false,
  isUploading: false,
  selectedObject: null,
  showStartScreen: true,
  isCameraReady: false,
  isAboutVisible: false,
  isShareVisible: false,
  isMuted: settings.isMuted,
  uiBottom: getAddressBarHeight(),
  isDeviceSupported: true,
  showExplanation: false,
}

export default (state = initialState, action) => {
  if (state.hydrated === true) {
    const newState = {
      ...initialState,
      ...state,
      hydrated: false,
    }

    return newState
  }

  switch (action.type) {
    case constants.START_APP:
      return {
        ...state,
        showStartScreen: false,
      }

    case constants.RESTART_APP:
      return {
        ...state,
        showStartScreen: true,
        isAboutVisible: false,
        isShareVisible: false,
      }

    case constants.SELECT_OBJECT:
      return {
        ...state,
        selectedObject: action.data.name,
        isConfirming: false,
      }

    case constants.UNSELECT_OBJECT:
      return {
        ...state,
        selectedObject: null,
        isConfirming: false,
      }

    case constants.CONFIRM_ASSIGNMENT:
      return {
        ...state,
        isConfirming: true,
      }

    case constants.CANCEL_ASSIGNMENT:
      return {
        ...state,
        isConfirming: false,
      }

    case constants.UPLOAD_REQUEST:
      return {
        ...state,
        isUploading: true,
      }

    case constants.UPLOAD_SUCCESS:
      return {
        ...state,
        isUploading: false,
      }

    case constants.UPLOAD_ERROR:
      return {
        ...state,
        isUploading: false,
      }

    case constants.AUDIO_LOADED:
      return {
        ...state,
        isLoadingAudio: false,
      }

    case constants.GLTF_REQUEST:
      return {
        ...state,
        isLoadingGLTF: true,
      }

    case constants.GLTF_PROGRESS:
      return {
        ...state,
        gltfProgress: action.data,
      }

    case constants.GLTF_LOADED:
      return {
        ...state,
        isLoadingGLTF: false,
      }

    case constants.GLTF_ERROR:
      return {
        ...state,
        gltfLoadingError: true,
      }

    case constants.CAMERA_READY:
      return {
        ...state,
        isCameraReady: true,
      }

    case constants.SHOW_ABOUT:
      return {
        ...state,
        isAboutVisible: true,
      }

    case constants.HIDE_ABOUT:
      return {
        ...state,
        isAboutVisible: false,
      }

    case constants.SHOW_SHARE_OVERLAY:
      return {
        ...state,
        isShareVisible: action.data,
      }

    case constants.TOGGLE_SOUND:
      return {
        ...state,
        isMuted: !state.isMuted,
      }

    case constants.SET_UI_BOTTOM:
      return {
        ...state,
        uiBottom: action.data,
      }

    case constants.DEVICE_NOT_SUPPORTED:
      return {
        ...state,
        isDeviceSupported: false,
      }

    case constants.SHOW_EXPLANATION:
      return {
        ...state,
        showExplanation: true,
      }

    case constants.HIDE_EXPLANATION:
      return {
        ...state,
        showExplanation: false,
      }

    default:
      return state
  }
}
