import React from 'react'
import SvgButton from './SvgButton'

const ShareButton = props => (
  <SvgButton id="Share" {...props}>
    <svg width="41.098" height="31" viewBox="0 0 41.098 31">
      <path d="M32 29H2V2h15V0H0v31h34v-8h-2z" />
      <path d="M11 19.967V22h2v-.875c0-3.416.875-6.11 2.6-8a9.745 9.745 0 0 1 4.7-2.749A10.671 10.671 0 0 1 22.906 10H32v5.379l9.1-6.39L32 2.6V8h-8.13c-.368 0-5.691-.019-9.408 3.357A11.1 11.1 0 0 0 11 19.967z" />
    </svg>
  </SvgButton>
)

export default ShareButton
