import * as THREE from 'three'
import mitt from 'mitt'
import { TweenLite } from 'gsap/TweenLite'
import device from 'current-device'

import Page from './utils/Page'
import TextureManager from './utils/TextureManager'
import settings from './settings'
import store from './store'
import { handleAnimationTap } from './actions/app'

import ObjectMap from './utils/ObjectMap'

import Renderer from './animation/Renderer'
import Scene from './animation/Scene'
import Camera from './animation/Camera'
import AnimationController from './animation/AnimationController'
import PlaybackController from './utils/PlaybackController'
import WebcamScene from './animation/WebcamScene'
import AnimationFrame from './utils/AnimationFrame'
import constants from './actions/constants'
import getAddressBarHeight from './utils/getAddressBarHeight'

// for debugging with chrome extension
// window.THREE = THREE

class Viewer {
  raycaster = new THREE.Raycaster()
  isRendering = true
  ratio = 1
  iosBarHeight = 0
  fader = undefined

  constructor() {
    Object.assign(this, mitt())

    this.render = this.render.bind(this)
    this.handleResize = this.handleResize.bind(this)
  }

  async init(element) {
    this.renderer = Renderer.init(element)
    this.scene = await Scene.init()

    // for debugging with chrome extension
    // window.scene = this.scene

    ObjectMap.setScene(this.scene)

    this.camera = Camera.get()
    this.controller = AnimationController

    this.isLoaded = true

    TextureManager.init(this.scene, settings.animation.inOutPoints)
    TextureManager.update(PlaybackController.frame)

    this.emit('load')

    this.initEvents()
    this.render()

    this.animation = new AnimationFrame(60, this.render)
    this.animation.start()
  }

  initEvents() {
    this.renderer.domElement.addEventListener('click', this.handleClick)
    Page.on('resize', this.handleResize)
  }

  // TODO: move this to Renderer and Camera
  handleResize({ width, height }) {
    const newHeight = Page.isIos && Page.aspect > 1 ? height + 1 : height
    this.updateIosBar()

    if (this.renderer) {
      this.renderer.setSize(width, newHeight)
    }

    if (this.camera) {
      this.camera.aspect = width / height
      this.camera.updateProjectionMatrix()
    }
  }

  // TODO: move interaction to separate controller
  async handleClick(e) {
    if (!device.desktop()) {
      store.dispatch(handleAnimationTap(e))
    }
  }

  raycast(e) {
    const coords = {
      x: (e.clientX / Page.width) * 2 - 1,
      y: -(e.clientY / Page.height) * 2 + 1,
    }
    this.raycaster.setFromCamera(coords, this.camera)
    const intersected = this.raycaster.intersectObjects(this.scene.children, true)
    this.selectedObject = intersected.length ? intersected[0].object : false
    return this.selectedObject
  }

  fadeOutObjectsExcept(except) {
    const { selectedObject } = this
    const webObject = WebcamScene.isUsed && WebcamScene.scene.getObjectByName(except, true)
    if (WebcamScene.isUsed && webObject) {
      // in case the camera switched we just need to update
      webObject.material = WebcamScene.canvasMaterial
      webObject.material.needsUpdate = true
      if (this.fader && this.fader.isActive()) this.fader.kill()
      this.ratio = 0
    } else {
      // otherwise clone the whole tree the object is part of...
      // first clone the selected object
      const cloned = selectedObject.clone()

      if (WebcamScene.canvasMaterial) {
        // use the canvas material for it
        cloned.material = WebcamScene.canvasMaterial
        cloned.material.needsUpdate = true
      }
      // else we probably haven't initialised the camera yet...

      if (selectedObject.parent === this.scene) {
        // in case it's not a nested object we can just add it
        WebcamScene.add(cloned, cloned)
      } else {
        // otherwise we need to find out how it's nested
        // we need to copy the nested structure `cause that also determines position and rotation

        // find the parents...
        const parents = ObjectMap.getParents(except).reverse()

        // keep adding/cloning the parents to duplicate the tree structure
        // but without the unncessary other children
        let prev
        let container
        parents.forEach(p => {
          const c = p.clone(false)
          if (prev) {
            prev.add(c)
          } else {
            prev = c
            container = prev
          }
          prev = c
        })
        // finally add the cloned item
        prev.add(cloned)
        // and add it to the webcam scene
        WebcamScene.add(container, cloned)
      }

      // fade the color of the original object to black for scene mixing (with the shader)
      // store the original color so we can get it back later
      this.originalColor = selectedObject.material.color.clone()
      TweenLite.to(selectedObject.material.color, 0.5, {
        r: 0,
        g: 0,
        b: 0,
        onUpdate: () => {
          selectedObject.material.needsUpdate = true
        },
      })

      // animate the mixing of the scenes
      TweenLite.to(this, 0.5, {
        ratio: 0,
      })
    }
  }

  fadeInObjects() {
    // restore color, based on if object has texture or not
    const color = !this.selectedObject.material.map
      ? this.originalColor
      : {
        r: 1,
        g: 1,
        b: 1,
      }

    // and animate the color change
    TweenLite.to(this.selectedObject.material.color, 0.5, {
      ...color,
      onUpdate: () => {
        this.selectedObject.material.needsUpdate = true
      },
    })

    // animate the scene mixing
    this.fader = TweenLite.to(this, 0.5, {
      ratio: 1,
      onComplete: WebcamScene.clear,
      onOverwrite: WebcamScene.clear,
    })
  }

  updateIosBar() {
    if (Page.isIos) {
      // make sure the UI is always visible
      const newBarHeight = getAddressBarHeight()
      if (newBarHeight !== this.iosBarHeight) {
        store.dispatch({ type: constants.SET_UI_BOTTOM, data: newBarHeight })
        this.iosBarHeight = newBarHeight
        this.handleResize({ width: Page.width, height: Page.height })
      }
    }
  }

  startBenchmark() {
    this.benchmark = []
  }

  // calculate average time for rendering a frame
  // being started after entering world
  // if time is above threshold we adjust the frame rate of the animation
  // this should make everything at least a bit smoother
  updateBenchmark(time) {
    // make sure we only add numbers
    if (!Number.isNaN(time)) this.benchmark.push(time)
    // we calculate the average after 120 ticks/renders
    if (this.benchmark.length > 120) {
      const total = this.benchmark.reduce((acc, cur) => acc + cur, 0)
      const avg = total / 120
      // for 60 fps the max time per frame is 1/60 = 16.67ms
      if (avg > 10) {
        this.animation.fps = 30
      }
      console.log('working on', this.animation.fps, 'fps')
      this.benchmark = null
    }
  }

  render() {
    let t0
    if (this.benchmark) {
      t0 = performance.now()
    }

    Camera.tick()
    PlaybackController.tick()

    this.updateIosBar()

    if (this.hasSelection && WebcamScene.canvasMaterial) {
      WebcamScene.canvasMaterial.map.needsUpdate = true
    }

    if (this.isRendering) {
      TextureManager.update(PlaybackController.frame)
      Scene.mixer.render(null, this.ratio)
    }
    this.emit('render', PlaybackController.frame)

    if (this.benchmark) {
      this.updateBenchmark(performance.now() - t0)
    }
  }
}

export default new Viewer()
