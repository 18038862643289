const instructions = {
  1: {
    groupId: 1,
    instruction_en: 'Take a photo of grass from above',
    confirmation_en: 'Is this the right type of grass?',
    quote1_en: 'The leaf of a potted plant will do...',
    quote2_en: 'A movement starts from the grassroots',
    quote3_en: "What's grass like?",
    instruction_nl: 'Maak van bovenaf een foto van gras',
    confirmation_nl: 'Is dit het goede gras?',
    quote1_nl: 'Of van iets grasachtigs',
    quote2_nl: "Elke beweging begint vanuit z'n wortels",
    quote3_nl: 'Waar lijkt het op?',
  },
  2: {
    groupId: 2,
    instruction_en: 'Take a photo of soil from above',
    confirmation_en: 'Is this really soil?',
    quote1_en: 'Everything grows in soil',
    quote2_en: 'Who lives there?',
    quote3_en: "What's underneath it?",
    instruction_nl: 'Maak van bovenaf een foto van grond',
    confirmation_nl: 'Is dit echt aarde?',
    quote1_nl: 'De aarde laat alles groeien',
    quote2_nl: 'Wie woont daar?',
    quote3_nl: 'Wat zit eronder?',
  },
  3: {
    groupId: 3,
    instruction_en: 'Take a photo of a road from above',
    confirmation_en: 'Is this the road?',
    quote1_en: 'Are all roads connected, somehow?',
    quote2_en: 'How many directions does it go in?',
    quote3_en: 'Do ants use roads?',
    instruction_nl: 'Maak van bovenaf een foto van een weg',
    confirmation_nl: 'Is dit de weg?',
    quote1_nl: 'Zijn alle wegen op de een of andere manier verbonden?',
    quote2_nl: 'Waarheen gaat de weg?',
    quote3_nl: 'Maken mieren gebruik van wegen?',
  },
  4: {
    groupId: 4,
    instruction_en: 'Take a photo of a tree',
    confirmation_en: 'Does this tree fit the scene?',
    quote1_en: 'Half of the tree is underground',
    quote2_en: 'Some trees can live for thousands of years',
    quote3_en: 'Does it like to hug?',
    instruction_nl: 'Maak een foto van een boom',
    confirmation_nl: 'Past deze boom bij de omgeving?',
    quote1_nl: 'De helft van een boom zit onder de grond',
    quote2_nl: 'De oudste boom ter wereld is ruim 9500 jaar',
    quote3_nl: 'Houdt-ie van knuffelen?',
  },
  5: {
    groupId: 5,
    instruction_en: 'Take a photo of a mountain',
    confirmation_en: 'Happy with this mountain?',
    quote1_en: 'No mountains around? Find a substitute.',
    quote2_en: 'My friend wears a sweater with a mountain',
    quote3_en: "Let's take mountains to the snow",
    instruction_nl: 'Maak een foto van een berg',
    confirmation_nl: 'Blij met deze berg?',
    quote1_nl: 'Geen bergen te bekennen? Zoek een vervanger!',
    quote2_nl: 'Wanneer is een berg een berg?',
    quote3_nl: 'Of laat de berg naar jou toe komen',
  },
  6: {
    groupId: 6,
    instruction_en: 'Take a photo of a pool',
    confirmation_en: 'Is this a pool?',
    quote1_en: 'A decent amount of water',
    quote2_en: 'Primordial soup',
    quote3_en: 'Heavy liquid',
    instruction_nl: 'Maak een foto van een plas',
    confirmation_nl: 'Is dit een plas?',
    quote1_nl: 'Een flinke hoeveelheid water',
    quote2_nl: 'Lijkt het op oersoep?',
    quote3_nl: 'Laat het vloeien',
  },
  7: {
    groupId: 7,
    instruction_en: 'Take a photo of a cloud',
    confirmation_en: 'Is this a cloud?',
    quote1_en: 'Go outside',
    quote2_en: 'Or find a window',
    quote3_en: 'Seen Memory Vapor in Neuhaus yet?',
    instruction_nl: 'Maak een foto van een wolk',
    confirmation_nl: 'Is dit een wolk?',
    quote1_nl: 'Ga lekker naar buiten',
    quote2_nl: 'Of loop naar een raam',
    quote3_nl: 'Memory Vapor in Neuhaus al gezien?',
  },
  8: {
    groupId: 8,
    instruction_en: 'Take a photo of the sky',
    confirmation_en: 'Is this the sky?',
    quote1_en: 'From Earth or from the Universe?',
    quote2_en: 'Airspace',
    quote3_en: 'Enough space?',
    instruction_nl: 'Maak een foto van de lucht',
    confirmation_nl: 'Is dit de lucht?',
    quote1_nl: 'Vanaf de aarde of het heelal?',
    quote2_nl: 'luchtruim',
    quote3_nl: 'Is het luchtig genoeg?',
  },
  9: {
    groupId: 9,
    instruction_en: 'Take a photo of a pose',
    confirmation_en: 'Is this the best you can do?',
    quote1_en: 'Hang like a plant',
    quote2_en: 'Dance like a butterfly',
    quote3_en: 'Sting like a bee',
    instruction_nl: 'Maak een foto van een pose',
    confirmation_nl: 'Is dit het beste wat je kan doen?',
    quote1_nl: 'Hang als een plant',
    quote2_nl: 'Dans als een vlinder',
    quote3_nl: 'Steek als een bij',
  },
  10: {
    groupId: 10,
    instruction_en: 'Take a photo of the pavement',
    confirmation_en: 'Is this the pavement?',
    quote1_en: "There's a beach under those paving stones",
    quote2_en: 'The lines are made of lava',
    quote3_en: 'Can you seen life in there?',
    instruction_nl: 'Maak een foto van de stoep',
    confirmation_nl: 'Is dit de stoep?',
    quote1_nl: 'Onder de straatstenen ligt het strand',
    quote2_nl: 'De lijntjes zijn lava!',
    quote3_nl: 'Zie je ook leven?',
  },
  11: {
    groupId: 11,
    instruction_en: 'Take a photo of a grey surface',
    confirmation_en: 'Is this really a grey surface?',
    quote1_en: 'Does grey contain all colours?',
    quote2_en: 'Is it happy or sad?',
    quote3_en: 'Blending in',
    instruction_nl: 'Maak een foto van een grijs oppervlak',
    confirmation_nl: 'Is dit echt een grijs oppervlak?',
    quote1_nl: 'Zitten alle andere kleuren in grijs?',
    quote2_nl: 'Is het happy of sad?',
    quote3_nl: 'Opgaan in de rest',
  },
  12: {
    groupId: 12,
    instruction_en: 'Take a photo of something shiny',
    confirmation_en: 'Is this the shiniest thing near you?',
    quote1_en: 'Radiate, glimmer, sparkle',
    quote2_en: 'The shine of age, the shine of youth',
    quote3_en: 'Look at it shine',
    instruction_nl: 'Maak een foto van iets dat glanst',
    confirmation_nl: 'Is dit het meest glanzende dat je kon vinden?',
    quote1_nl: 'Glans, glinstering, fonkeling',
    quote2_nl: "Kijk 'm shinen",
    quote3_nl: 'Glinstert iets glanzends nog steeds als er geen licht is?',
  },
  13: {
    groupId: 13,
    instruction_en: 'Photograph a text',
    confirmation_en: 'Is it legible?',
    quote1_en: 'What do you want it to say?',
    quote2_en: 'Letters, words, sentences,',
    quote3_en: 'Paragraphs, chapters, books, libraries',
    instruction_nl: 'Maak een foto van een tekst',
    confirmation_nl: 'Is de tekst goed leesbaar?',
    quote1_nl: 'Wil je daar iets mee zeggen?',
    quote2_nl: 'Letters, woorden, zinnen,',
    quote3_nl: "Alinea's, hoofdstukken, boeken, bibliotheken",
  },
  14: {
    groupId: 14,
    instruction_en: 'Photograph a thing of beauty',
    confirmation_en: 'Would you call this beautiful?',
    quote1_en: 'Will you remember this tomorrow?',
    quote2_en: 'Goldfish',
    quote3_en: 'Let go of it',
    instruction_nl: 'Maak een foto van iets moois',
    confirmation_nl: 'Zou je dit mooi noemen?',
    quote1_nl: 'Kun je je dit morgen nog herinneren?',
    quote2_nl: 'Vindt het zichzelf ook mooi?',
    quote3_nl: 'Waar is het beest?',
  },
  15: {
    groupId: 15,
    instruction_en: 'Photograph a painting',
    confirmation_en: 'What title would you give it?',
    quote1_en: 'The painting sees you too',
    quote2_en: 'What title would you give it?',
    quote3_en: 'Oil paint never really dries',
    instruction_nl: 'Maak een foto van een schilderij',
    confirmation_nl: 'Welke titel zou je het?',
    quote1_nl: 'Welke titel krijgt het?',
    quote2_nl: 'Het schilderij ziet jou ook',
    quote3_nl: 'Olieverf droogt nooit helemaal',
  },
  16: {
    groupId: 16,
    instruction_en: 'Photograph a 50% grey wall',
    confirmation_en: 'Is this a wall?',
    quote1_en: 'Something that separates spaces',
    quote2_en: 'I dont mind having a big beautiful door in this wall.',
    quote3_en: 'A barrier, keeps things out',
    instruction_nl: 'Maak een foto van een muur die voor de helft grijs is',
    confirmation_nl: 'Is dit een muur?',
    quote1_nl: 'Een scheiding tussen ruimtes',
    quote2_nl: 'Is schaduw ook kleur?',
    quote3_nl: 'Een barrière, houdt dingen buiten',
  },
  17: {
    groupId: 17,
    instruction_en: 'Take a photo of a heroine',
    confirmation_en: 'Are you happy with this photo?',
    quote1_en: 'We are a different kind',
    quote2_en: 'Are all heroes famous?',
    quote3_en: 'Like the dolphins',
    instruction_nl: 'Maak een foto van een heldin',
    confirmation_nl: 'Blij met deze foto?',
    quote1_nl: 'Wij zijn een ander soort',
    quote2_nl: 'Zijn alle helden beroemd?',
    quote3_nl: 'Welke pose past daarbij?',
  },
  18: {
    groupId: 18,
    instruction_en: 'Photograph a concrete surface',
    confirmation_en: 'It this concrete?',
    quote1_en: 'This was a liquid once',
    quote2_en: 'Humans left a trace here',
    quote3_en: 'Solid as a rock',
    instruction_nl: 'Maak een foto van een betonnen oppervlak',
    confirmation_nl: 'Is dit beton?',
    quote1_nl: 'Dit was ooit een vloeistof',
    quote2_nl: 'Een blijvend spoor van mensen',
    quote3_nl: 'Rotsvast',
  },
  19: {
    groupId: 19,
    instruction_en: 'Photograph someone making a selfie',
    confirmation_en: 'Happy with this photo?',
    quote1_en: 'Do you look the way you see yourself in pictures?',
    quote2_en: 'Early 21st century: from self + -ie.',
    quote3_en: 'Lust, insecurity and true love',
    instruction_nl: 'Maak een foto van iemand die een selfie maakt',
    confirmation_nl: 'Blij met deze foto?',
    quote1_nl: "Zie je eruit zoals je jezelf op foto's ziet?",
    quote2_nl: 'Wat je doet als je niks doet',
    quote3_nl: 'Camera, camera, in mijn telefoon, wie is het mooiste?',
  },
  20: {
    groupId: 20,
    instruction_en: 'Photograph a dancer',
    confirmation_en: 'Happy with this photo?',
    quote1_en: 'Do you have muscle memory?',
    quote2_en: 'Is dancing a human thing?',
    quote3_en: 'Does your body remember a dance?',
    instruction_nl: 'Maak een foto van een danser',
    confirmation_nl: 'Blij met deze foto?',
    quote1_nl: 'Heb je spiergeheugen?',
    quote2_nl: 'Is dansen alleen iets menselijks?',
    quote3_nl: 'Onthoudt je lichaam een dans?',
  },
  21: {
    groupId: 21,
    instruction_en: 'Photograph a classic human pose',
    confirmation_en: 'Is it a classic?',
    quote1_en: 'Could a tree strike a classic pose?',
    quote2_en: 'Could a mosquito strike a classic pose?',
    quote3_en: "Like a gentleman bringin' glamour back",
    instruction_nl: 'Maak een foto van een klassieke menselijke pose',
    confirmation_nl: 'Is dit echt een klassieke pose?',
    quote1_nl: 'Kan een boom een klassieke pose aannemen?',
    quote2_nl: 'Kan een mug een klassieke pose aannnemen?',
    quote3_nl: 'Als een gentleman die de glamour terugbrengt',
  },
  22: {
    groupId: 22,
    instruction_en: 'Photograph someone doing the Swish',
    confirmation_en: 'Is it looking good?',
    quote1_en: 'Is this the original?',
    quote2_en: "They know what's what",
    quote3_en: "But they don't know what's what",
    instruction_nl: 'Foto iemand het doen van de Swish',
    confirmation_nl: 'Is het ziet er goed uit?',
    quote1_nl: 'Is dit het origineel?',
    quote2_nl: 'Ze weten wat wat is',
    quote3_nl: 'Maar ze weten niet wat is wat',
  },
  23: {
    groupId: 23,
    instruction_en: 'Photograph a tiny being',
    confirmation_en: 'Is this a tiny being?',
    quote1_en: 'Size matters',
    quote2_en: 'Accustomed to danger',
    quote3_en: "Size doesn't matter",
    instruction_nl: 'Maak een foto van een klein wezentje',
    confirmation_nl: 'Is dit een klein wezentje?',
    quote1_nl: 'Het formaat doet er wél toe',
    quote2_nl: 'Gewend aan gevaar',
    quote3_nl: 'Het maakt niet uit hoe groot iets is',
  },
  24: {
    groupId: 24,
    instruction_en: 'Photograph a beautiful human',
    confirmation_en: 'Does this person look good?',
    quote1_en: 'Look in the mirror',
    quote2_en: 'See a new face',
    quote3_en: 'Feel good',
    instruction_nl: 'Maak een foto van een mooi mens',
    confirmation_nl: 'Vind je dit een mooi mens?',
    quote1_nl: 'Kijk in de spiegel',
    quote2_nl: 'Zie een nieuw gezicht',
    quote3_nl: 'Wat maakt mooi?',
  },
  25: {
    groupId: 25,
    instruction_en: 'Take a photo of speed',
    confirmation_en: 'Is it fast enough?',
    quote1_en: 'How fast does your blood flow?',
    quote2_en: 'How fast does the climate change?',
    quote3_en: 'How fast is hyper frequency trading?',
    instruction_nl: 'Maak een foto van snelheid',
    confirmation_nl: 'Is het snel genoeg?',
    quote1_nl: 'Hoe snel stroomt je bloed?',
    quote2_nl: 'Hoe snel gaat klimaatverandering?',
    quote3_nl: 'Hoe snel gaat hyper frequency trading?',
  },
  26: {
    groupId: 26,
    instruction_en: 'Take a photo of something red',
    confirmation_en: 'Are you happy with this photo?',
    quote1_en: 'Rose, blood, Ferrari',
    quote2_en: 'Raincoat, pencil, neon, flag',
    quote3_en: 'Cup, poster, cross, stop',
    instruction_nl: 'Maak een foto van iets roods',
    confirmation_nl: 'Blij met deze foto?',
    quote1_nl: 'Roos, bloed, Ferrari',
    quote2_nl: 'Lippen, bakstenen, ondergaande zon',
    quote3_nl: 'Feeling hot hot hot',
  },
  27: {
    groupId: 27,
    instruction_en: 'Take a photo of something yellow',
    confirmation_en: 'Are you happy with this photo?',
    quote1_en: 'Egg, flower, submarine',
    quote2_en: 'Butter, raincoat again, signpost',
    quote3_en: 'Expressed by a middle C',
    instruction_nl: 'Maak een foto van iets geels',
    confirmation_nl: 'Blij met deze foto?',
    quote1_nl: 'Ei, bloem, onderzeeër',
    quote2_nl: 'Boter, regenjas, banaan',
    quote3_nl: 'Papier, kanarie, bloem',
  },
  28: {
    groupId: 28,
    instruction_en: 'Take a photo of something blue',
    confirmation_en: 'Are you happy with this photo?',
    quote1_en: 'Blues before sunrise',
    quote2_en: 'Dabadee Dabadaa',
    quote3_en: 'Anything blue really...',
    instruction_nl: 'Maak een foto van iets blauws',
    confirmation_nl: 'Blij met deze foto?',
    quote1_nl: 'Blues before sunrise',
    quote2_nl: 'Dabadee Dabadaa',
    quote3_nl: 'Brrrrrrr',
  },
  29: {
    groupId: 29,
    instruction_en: 'Take a photo of an eye from up close',
    confirmation_en: 'Happy with this eye?',
    quote1_en: "I see, I see, what you don't see",
    quote2_en: 'The iris',
    quote3_en: 'Remote sensing of light waves',
    instruction_nl: 'Maak van dichtbij een foto van een oog',
    confirmation_nl: 'Blij met dit oog?',
    quote1_nl: 'Ik zie, ik zie, wat jij niet ziet',
    quote2_nl: 'De iris',
    quote3_nl: 'Eng of mooi?',
  },
  30: {
    groupId: 30,
    instruction_en: 'Take a photo of an ear from up close',
    confirmation_en: 'Happy with this ear?',
    quote1_en: "There's a sound at the end of the tunnel\n",
    quote2_en: 'A difference between listening and hearing',
    quote3_en: 'The edge of audibility',
    instruction_nl: 'Maak van dichtbij een foto van een oor',
    confirmation_nl: 'Blij met dit oor?',
    quote1_nl: 'Er is een geluid aan het eind van de tunnel\n',
    quote2_nl: 'Het verschil tussen horen en luisteren',
    quote3_nl: 'Dat hoort toch niet',
  },
  31: {
    groupId: 31,
    instruction_en: 'Take a photo of the inside of your hand',
    confirmation_en: 'Does it tell you something?',
    quote1_en: 'What does it tell?',
    quote2_en: 'Holding, shaking, touching, striking',
    quote3_en: 'A map or a landscape in itself?',
    instruction_nl: 'Maak een foto van de binnenkant van je hand',
    confirmation_nl: 'Blij met deze foto?',
    quote1_nl: 'Vertelt het iets?',
    quote2_nl: 'Houden, schudden, aanraken, opvallend',
    quote3_nl: 'Een kaart of op zichzelf een landschap?',
  },
  32: {
    groupId: 32,
    instruction_en: 'Take a photo of a non-human',
    confirmation_en: 'Are you happy with this photo?',
    quote1_en: 'Tooth and fang, eye and claw',
    quote2_en: 'Bambino Sphynx',
    quote3_en: 'Portait or still life?',
    instruction_nl: 'Maak een foto van een niet-mens',
    confirmation_nl: 'Blij met deze foto?',
    quote1_nl: 'Tand, slagtand, oog en klauw',
    quote2_nl: 'Heeft het niets menselijks?',
    quote3_nl: 'Wat is het verschil met een mens?',
  },
  33: {
    groupId: 33,
    instruction_en: 'Take a photo of something that smells',
    confirmation_en: 'Are you happy with this photo?',
    quote1_en: 'Things can smell, but can things smell…?',
    quote2_en: 'Poopsie Slime Surprise!',
    quote3_en: 'Ahhh',
    instruction_nl: 'Maak een foto van iets dat ruikt',
    confirmation_nl: 'Blij met deze foto?',
    quote1_nl: 'Dingen kunnen ruiken, maar kunnen dingen ruiken?',
    quote2_nl: 'Poopsie slime surprise!',
    quote3_nl: 'Ahhh',
  },
  34: {
    groupId: 34,
    instruction_en: 'Take a photo of a good idea',
    confirmation_en: 'Are you happy with this photo?',
    quote1_en: 'Ping!',
    quote2_en: 'Is this idea good for all beings?',
    quote3_en: 'Confined to the world of concepts',
    instruction_nl: 'Maak een foto van een goed idee',
    confirmation_nl: 'Blij met deze foto?',
    quote1_nl: 'Eureka!',
    quote2_nl: 'Is dit een goed idee voor alle wezens?',
    quote3_nl: 'Ping!',
  },
  35: {
    groupId: 35,
    instruction_en: 'Take a photo of something that thinks',
    confirmation_en: 'Are you happy with this photo?',
    quote1_en: 'The process of considering',
    quote2_en: "What's the Thinker thinking about?",
    quote3_en: 'Is thinking something only humans can do?',
    instruction_nl: 'Maak een foto van iets dat denkt',
    confirmation_nl: 'Blij met deze foto?',
    quote1_nl: 'Het proces van ergens bij stilstaan',
    quote2_nl: 'Waar denkt de Denker aan?',
    quote3_nl: 'Is denken iets dat alleen mensen kunnen doen?',
  },
  36: {
    groupId: 36,
    instruction_en: 'Take a photo of something that can turn in to something else',
    confirmation_en: 'Are you happy with this photo?',
    quote1_en: 'Transformers',
    quote2_en: "What does it want to be when it's older?",
    quote3_en: 'Change to remain the same',
    instruction_nl: 'Maak een foto van iets dat iets anders kan worden',
    confirmation_nl: 'Blij met deze foto?',
    quote1_nl: 'Transformers',
    quote2_nl: 'Wat wil het later worden?',
    quote3_nl: 'Veranderen om het zelfde te kunnen blijven',
  },
  37: {
    groupId: 37,
    instruction_en: 'Take a photo of something that’s invisible',
    confirmation_en: 'Do you want to submit this... thing?',
    quote1_en: 'Black, foggy, transparent, feelings, abstractions',
    quote2_en: 'Someone, somehow, somewhere...',
    quote3_en: 'All things exist equally',
    instruction_nl: 'Maak een foto van iets onzichtbaars',
    confirmation_nl: 'Is dit wat je wilde insturen?',
    quote1_nl: 'Zwart, mistig, transparant, gevoelens, abstracties',
    quote2_nl: 'Iets, iemand, ergens, op de een of andere manier',
    quote3_nl: 'Alles bestaat evenveel',
  },
  38: {
    groupId: 38,
    instruction_en: 'Take a photo of something that doesn’t love humans',
    confirmation_en: 'Are you happy with this photo?',
    quote1_en: "It's ok to be human",
    quote2_en: 'Do you agree?',
    quote3_en: 'Is it ok to be human?',
    instruction_nl: 'Maak een foto van iets dat niet van mensen houdt',
    confirmation_nl: 'Blij met deze foto?',
    quote1_nl: 'Het is niet erg om mens te zijn',
    quote2_nl: 'Ben je het ermee eens?',
    quote3_nl: 'Is het niet erg om mens te zijn?',
  },
  39: {
    groupId: 39,
    instruction_en: 'Take a photo of something slow',
    confirmation_en: 'Are you happy with this photo?',
    quote1_en: 'Time is jelly',
    quote2_en: 'Time takes place',
    quote3_en: 'Countdown',
    instruction_nl: 'Maak een foto van iets langzaams',
    confirmation_nl: 'Blij met deze foto?',
    quote1_nl: 'De tijd is als pudding',
    quote2_nl: 'Tijd vindt plaats',
    quote3_nl: 'Slow motion',
  },
  40: {
    groupId: 40,
    instruction_en: 'Take a photo of a collection',
    confirmation_en: 'Are you happy with this photo?',
    quote1_en: 'Collections are never complete',
    quote2_en: 'The rest is history',
    quote3_en: "There's power in some numbers",
    instruction_nl: 'Maak een foto van een verzameling',
    confirmation_nl: 'Blij met deze foto?',
    quote1_nl: 'Verzamelingen zijn nooit compleet',
    quote2_nl: 'De rest is geschiedenis',
    quote3_nl: 'Eekhoorns verstoppen hun verzamelingen',
  },
  41: {
    groupId: 41,
    instruction_en: 'Photograph a memory',
    confirmation_en: 'Are you happy with this photo?',
    quote1_en: 'What things and beings share this memory?',
    quote2_en: 'Kind of a memory of a memory',
    quote3_en: "It's gone",
    instruction_nl: 'Maak een foto van een herinnering',
    confirmation_nl: 'Blij met deze foto?',
    quote1_nl: 'Met welke wezens en dingen deel je deze herinnering?',
    quote2_nl: 'Eigenlijk een herinnering van een herinnering',
    quote3_nl: 'Het is er niet meer',
  },
  42: {
    groupId: 42,
    instruction_en: 'Photograph a portal to another world',
    confirmation_en: 'Think this is a portal?',
    quote1_en: 'Portals lure you to another dimension',
    quote2_en: 'How does it feel in here...?',
    quote3_en: 'Built from obsidian',
    instruction_nl: 'Maak een foto van een doorgang naar een andere wereld',
    confirmation_nl: 'Denk je dat dit een portaal of doorgang is?',
    quote1_nl: 'Doorgangen lokken je naar een andere dimensie',
    quote2_nl: 'Hoe voelt het hier?',
    quote3_nl: 'Wie wonen daar?',
  },
  43: {
    groupId: 43,
    instruction_en: 'Photograph something young',
    confirmation_en: 'Did this only exist for a short time?',
    quote1_en: 'Does it ask why? Does it say NO?',
    quote2_en: 'When is it old?',
    quote3_en: 'Care for it',
    instruction_nl: 'Maak een foto van iets jongs',
    confirmation_nl: 'Bestaat dit nog maar kort?',
    quote1_nl: 'Vraagt het waarom? Zegt het nee?',
    quote2_nl: 'Wanneer is het oud?',
    quote3_nl: 'Zorg er goed voor',
  },
  44: {
    groupId: 44,
    instruction_en: 'Photograph something old',
    confirmation_en: 'Are you happy with this photo?',
    quote1_en: 'Some things get shiny with age',
    quote2_en: 'Some things grow wrinkled with age',
    quote3_en: 'The universe is 13.9 billions years old',
    instruction_nl: 'Maak een foto van iets ouds',
    confirmation_nl: 'Blij met deze foto?',
    quote1_nl: 'Sommige dingen krijgen hun glans wanneer ze ouder worden',
    quote2_nl: 'Sommige dingen krijgen rimpels als ze ouder worden',
    quote3_nl: 'Het heelal is 13,9 miljard jaar oud',
  },
  45: {
    groupId: 45,
    instruction_en: 'Photograph a clock',
    confirmation_en: 'Are you happy with this photo?',
    quote1_en: 'Now is not the moment',
    quote2_en: 'Time for a little something',
    quote3_en: "Time's nearly up...",
    instruction_nl: 'Maak een foto van een klok',
    confirmation_nl: 'Blij met deze foto?',
    quote1_nl: 'Nu is niet het moment',
    quote2_nl: 'Het is ergens tijd voor',
    quote3_nl: 'De tijd begint te dringen...',
  },
  46: {
    groupId: 46,
    instruction_en: 'Photograph something that can only happen today',
    confirmation_en: 'Are you happy with this photo?',
    quote1_en: 'What would today want you to remember?',
    quote2_en: 'Crush?',
    quote3_en: 'This is your moment',
    instruction_nl: 'Maak een foto van iets dat alleen vandaag kan gebeuren',
    confirmation_nl: 'Blij met deze foto?',
    quote1_nl: 'Wat zou vandaag willen dat je onthield?',
    quote2_nl: 'Crush?',
    quote3_nl: 'This is the moment',
  },
  47: {
    groupId: 47,
    instruction_en: 'Photograph a switch',
    confirmation_en: 'Are you happy with this switch?',
    quote1_en: 'Not one way or the other?',
    quote2_en: 'on / off',
    quote3_en: 'Choices to be made',
    instruction_nl: 'Maak een foto van een schakelaar',
    confirmation_nl: 'Blij met deze foto?',
    quote1_nl: 'Niet het ene of het andere',
    quote2_nl: 'aan / uit',
    quote3_nl: 'Keuzes maken',
  },
  48: {
    groupId: 48,
    instruction_en: 'Photograph a wheel',
    confirmation_en: 'Are you happy with this wheel?',
    quote1_en: 'Always returning',
    quote2_en: 'Roll with me',
    quote3_en: 'Pull me, push me',
    instruction_nl: 'Maak een foto van een wiel',
    confirmation_nl: 'Blij met deze foto?',
    quote1_nl: 'Komt altijd weer terug',
    quote2_nl: 'Draai me een rondje',
    quote3_nl: 'Rol met me mee',
  },
  49: {
    groupId: 49,
    instruction_en: 'Photograph a lens',
    confirmation_en: 'Are you happy with this photo?',
    quote1_en: 'Transparent curved material',
    quote2_en: 'All these cameras...',
    quote3_en: 'Can a lens see?',
    instruction_nl: 'Maak een foto van een lens',
    confirmation_nl: 'Blij met deze foto?',
    quote1_nl: 'Transparant gebogen materiaal',
    quote2_nl: "Al deze camera's ...",
    quote3_nl: 'Kan een lens zien?',
  },
  50: {
    groupId: 50,
    instruction_en: 'Photograph a camera',
    confirmation_en: 'Are you happy with this photo?',
    quote1_en: 'A machine that watches',
    quote2_en: 'Creating memories',
    quote3_en: 'Candid',
    instruction_nl: 'Maak een foto van een camera',
    confirmation_nl: 'Blij met deze foto?',
    quote1_nl: 'Een machine die op wacht staat',
    quote2_nl: 'Herinneringen maken',
    quote3_nl: 'Niets te verbergen?',
  },
  51: {
    groupId: 51,
    instruction_en: 'Photograph a screen',
    confirmation_en: 'Are you happy with this photo?',
    quote1_en: 'What’s a screen hiding?',
    quote2_en: 'Crashing in slowmotion',
    quote3_en: 'Forgetting to be human',
    instruction_nl: 'Maak een foto van een scherm',
    confirmation_nl: 'Blij met deze foto?',
    quote1_nl: 'Wat houdt een scherm verborgen?',
    quote2_nl: 'Onze trouwe vrienden',
    quote3_nl: 'Wat was een scherm vroeger?',
  },
  52: {
    groupId: 52,
    instruction_en: 'Photograph a cable',
    confirmation_en: 'Are you happy with this photo?',
    quote1_en: 'Battery 2%',
    quote2_en: 'How does it feel to be connected?',
    quote3_en: 'What else can a cable be?',
    instruction_nl: 'Maak een foto van een kabel',
    confirmation_nl: 'Blij met deze foto?',
    quote1_nl: 'Batterij 2%',
    quote2_nl: 'Hoe voelt het om aangesloten te worden?',
    quote3_nl: 'Wat kan een kabel nog meer zijn?',
  },
  53: {
    groupId: 53,
    instruction_en: 'Photograph something that makes noise',
    confirmation_en: 'Are you happy with this photo?',
    quote1_en: 'Can you hear all sounds?',
    quote2_en: 'Does silence exist?',
    quote3_en: 'Can you see noise?',
    instruction_nl: 'Maak een foto van iets luidruchtigs',
    confirmation_nl: 'Blij met deze foto?',
    quote1_nl: 'Kun je alle geluiden horen?',
    quote2_nl: 'Bestaat er stilte?',
    quote3_nl: 'Kun je geluiden zien?',
  },
  54: {
    groupId: 54,
    instruction_en: 'Photograph something only you can hear',
    confirmation_en: 'Are you happy with this photo?',
    quote1_en: "Are you sure you're not making this up?",
    quote2_en: 'Is it a secret?',
    quote3_en: 'Should others hear it too?',
    instruction_nl: 'Maak een foto van iets dat alleen jij kunt horen',
    confirmation_nl: 'Blij met deze foto?',
    quote1_nl: 'Weet je zeker dat je dit niet verzint?',
    quote2_nl: 'Is het een geheim?',
    quote3_nl: 'Zouden anderen dit ook moeten horen?',
  },
  55: {
    groupId: 55,
    instruction_en: 'Take a photo of a friend',
    confirmation_en: 'Are you happy with this photo?',
    quote1_en: 'What makes a friend a friend?',
    quote2_en: 'Can things be friends?',
    quote3_en: 'Can friends be things?',
    instruction_nl: 'Maak een foto van een vriend',
    confirmation_nl: 'Blij met deze foto?',
    quote1_nl: 'Wat maakt een vriend een vriend?',
    quote2_nl: 'Hebben dingen ook vrienden?',
    quote3_nl: 'Kunnen vrienden dingen zijn?',
  },
  56: {
    groupId: 56,
    instruction_en: 'Take a photo of ‘us’',
    confirmation_en: 'Are you happy with this photo?',
    quote1_en: 'Who is ‘us’?',
    quote2_en: "Who is 'them'?",
    quote3_en: "Who doesn't belong anywhere?",
    instruction_nl: "Maak een foto van 'ons', van 'wij'",
    confirmation_nl: 'Blij met deze foto?',
    quote1_nl: "Wie zijn 'wij'?",
    quote2_nl: "Wie zijn 'zij'?",
    quote3_nl: 'Wie hoort nergens bij?',
  },
  57: {
    groupId: 57,
    instruction_en: 'Take a photo of something next to you',
    confirmation_en: 'Are you happy with this photo?',
    quote1_en: 'The things that surround us, the others',
    quote2_en: 'Phone, keys, credit card',
    quote3_en: '20 kinds of cheese',
    instruction_nl: 'Maak een foto van iets naast je',
    confirmation_nl: 'Blij met deze foto?',
    quote1_nl: 'De dingen om ons heen, de anderen',
    quote2_nl: 'Telefoon, sleutels, creditcard',
    quote3_nl: 'Twintig soorten kaas',
  },
  58: {
    groupId: 58,
    instruction_en: 'Photograph someone opposing you',
    confirmation_en: 'Are you happy with this photo?',
    quote1_en: 'From this position',
    quote2_en: "It's all quiet over there",
    quote3_en: 'Bridge the gap',
    instruction_nl: 'Maak een foto van iemand tegenover je',
    confirmation_nl: 'Blij met deze foto?',
    quote1_nl: 'Vanaf hier',
    quote2_nl: "'T is stil aan de overkant",
    quote3_nl: 'Overbrug de afstand',
  },
  59: {
    groupId: 59,
    instruction_en: 'Take a photo of a mask',
    confirmation_en: 'Are you happy with this mask?',
    quote1_en: "What's it hiding?",
    quote2_en: 'Does your mask show a truth?',
    quote3_en: "Wasn't me.",
    instruction_nl: 'Maak een foto van een masker',
    confirmation_nl: 'Ben je blij met dit masker?',
    quote1_nl: 'Wat verstopt het?',
    quote2_nl: 'Laat je masker de werkelijkheid zien?',
    quote3_nl: 'Ik heb het niet gedaan',
  },
  60: {
    groupId: 60,
    instruction_en: 'Take a photo of sadness',
    confirmation_en: 'Happy with this sadness?',
    quote1_en: 'Something sad?',
    quote2_en: 'Can you comfort it?',
    quote3_en: 'What now?',
    instruction_nl: 'Maak een foto van verdriet',
    confirmation_nl: 'Ben je blij met dit verdriet?',
    quote1_nl: 'Een verdrietig ding?',
    quote2_nl: 'Kun je het troosten?',
    quote3_nl: 'Wat nu?',
  },
  61: {
    groupId: 61,
    instruction_en: 'Photograph happiness',
    confirmation_en: 'Are you happy with this photo?',
    quote1_en: 'Would this make non-humans happy too?',
    quote2_en: "Don't worry about happiness",
    quote3_en: 'Its overrated',
    instruction_nl: 'Maak een foto van geluk',
    confirmation_nl: 'Blij met deze foto?',
    quote1_nl: 'Worden ook niet-mensen hier gelukkig van?',
    quote2_nl: 'Houd dit vast!',
    quote3_nl: 'Het stelt niet zoveel voor',
  },
  62: {
    groupId: 62,
    instruction_en: 'Take a photo of a model',
    confirmation_en: 'Are you happy with this photo?',
    quote1_en: 'Simplified representation of a thing',
    quote2_en: 'A proposed structure',
    quote3_en: 'Naomi, Doutzen, Twiggy',
    instruction_nl: 'Maak een foto van een model',
    confirmation_nl: 'Blij met deze foto?',
    quote1_nl: 'Vereenvoudigde weergave van een ding',
    quote2_nl: 'Een voorgestelde structuur',
    quote3_nl: 'Naomi, Doutzen, Twiggy',
  },
  63: {
    groupId: 63,
    instruction_en: 'Photograph an inside/interior',
    confirmation_en: 'Are you happy with this photo?',
    quote1_en: 'The inner part of something',
    quote2_en: 'Is inside always safer?',
    quote3_en: 'Can a garment be an interior?',
    instruction_nl: 'Maak een foto van een interieur',
    confirmation_nl: 'Blij met deze foto?',
    quote1_nl: 'Het binnenste deel van iets',
    quote2_nl: 'Is binnenkant altijd veiliger?',
    quote3_nl: 'Kan een kledingstuk een interieur zijn?',
  },
  64: {
    groupId: 64,
    instruction_en: 'Photograph a solution',
    confirmation_en: 'Happy with this solution?',
    quote1_en: 'What could possibly go wrong?',
    quote2_en: 'Look for a fix',
    quote3_en: 'Temporal or final',
    instruction_nl: 'Maak een foto van een oplossing',
    confirmation_nl: 'Blij met deze oplossing?',
    quote1_nl: 'Wat zou er mis kunnen gaan?',
    quote2_nl: 'Op zoek naar een oplossing',
    quote3_nl: 'Tijdelijk of voor altijd',
  },
  65: {
    groupId: 65,
    instruction_en: 'Photograph an alternative',
    confirmation_en: 'Is this a good alternative?',
    quote1_en: 'Protest!',
    quote2_en: 'Change the system',
    quote3_en: 'Being eaten by zombies',
    instruction_nl: 'Maak een foto van een alternatief',
    confirmation_nl: 'Is dit een goed alternatief?',
    quote1_nl: 'Protest!',
    quote2_nl: 'Change the system',
    quote3_nl: 'Opgegeten worden door zombies?',
  },
  66: {
    groupId: 66,
    instruction_en: 'Photograph an eye',
    confirmation_en: 'Are you happy with this eye?',
    quote1_en: 'Count the things with eyes around you',
    quote2_en: 'Is the camera of your device an eye?',
    quote3_en: 'Eyes upclose are so beautiful',
    instruction_nl: 'Maak een foto van een oog',
    confirmation_nl: 'Ben je tevreden met dit oog?',
    quote1_nl: 'Tel hoeveel dingen om je heen ogen hebben',
    quote2_nl: "Is de camera het 'oog' van je machine?",
    quote3_nl: 'Ogen zijn zo mooi van dichtbij',
  },
  67: {
    groupId: 67,
    instruction_en: 'Photograph a nose',
    confirmation_en: 'Are you happy with this nose?',
    quote1_en: 'Advanced gas sensor',
    quote2_en: 'Used for breathing and smelling',
    quote3_en: 'A non-human nose?',
    instruction_nl: 'Maak een foto van een neus',
    confirmation_nl: 'Ben je blij met deze neus?',
    quote1_nl: 'Geavanceerde sensor voor gas',
    quote2_nl: 'Om mee te ademen en mee te ruiken',
    quote3_nl: 'Een niet-menselijke neus?',
  },
  68: {
    groupId: 68,
    instruction_en: 'Photograph your hair',
    confirmation_en: 'Is the hair visible?',
    quote1_en: 'Try to only have hair in the frame',
    quote2_en: 'Threadlike strands growing from the skin',
    quote3_en: 'Of mammals, and yeasts',
    instruction_nl: 'Maak een foto van haren',
    confirmation_nl: 'Is het haar zichtbaar?',
    quote1_nl: 'Probeer om alleen maar haar in het kader te houden',
    quote2_nl: 'Een soort draadjes die uit de huid groeien',
    quote3_nl: 'Bij zoogdieren. En bij schimmels',
  },
  69: {
    groupId: 69,
    instruction_en: 'Photograph a leg',
    confirmation_en: 'Are you happy with this photo?',
    quote1_en: 'Supporter of chairs, tables and other structures',
    quote2_en: "Animal's limbs",
    quote3_en: 'Stand, walk, run, jump',
    instruction_nl: 'Maak een foto van een been',
    confirmation_nl: 'Blij met deze foto?',
    quote1_nl: 'Als je ze poten noemt hebben tafels en stoelen ook benen',
    quote2_nl: 'De ledematen van dieren',
    quote3_nl: 'Staan, lopen, rennen, springen',
  },
  70: {
    groupId: 70,
    instruction_en: 'Photograph the back of a head',
    confirmation_en: 'Are you happy with this photo?',
    quote1_en: "I recognise the back of my mother's head",
    quote2_en: 'What makes it the back or front?',
    quote3_en: 'What does it look like?',
    instruction_nl: 'Maak een foto van de achterkant van een hoofd',
    confirmation_nl: 'Blij met deze foto?',
    quote1_nl: 'Ik herken de achterkant van het hoofd van mijn moeder',
    quote2_nl: 'Wat maakt het de voor- of achterkant?',
    quote3_nl: 'Waar lijkt het op?',
  },
  71: {
    groupId: 71,
    instruction_en: 'Photograph the back of your tongue',
    confirmation_en: 'Are you happy with this photo?',
    quote1_en: "It's a muscle",
    quote2_en: 'Used for tasting, licking, swallowing...',
    quote3_en: 'and, in humans, articulating speech.',
    instruction_nl: 'Maak een foto van de achterkant van je tong',
    confirmation_nl: 'Blij met deze foto?',
    quote1_nl: 'Het is een spier',
    quote2_nl: 'Om mee te proeven, likken, slikken',
    quote3_nl: 'en - bij mensen - om mee te praten',
  },
  72: {
    groupId: 72,
    instruction_en: 'Photograph a bit of hairy skin',
    confirmation_en: 'Are you happy with this photo?',
    quote1_en: 'Vellus hair',
    quote2_en: 'Terminal hair',
    quote3_en: 'Visible body hair.',
    instruction_nl: 'Maak een foto van een stukje behaarde huid',
    confirmation_nl: 'Blij met deze foto?',
    quote1_nl: 'Donshaar',
    quote2_nl: 'Stoppels?',
    quote3_nl: 'Zichtbaar lichaamshaar.',
  },
  73: {
    groupId: 73,
    instruction_en: 'Photograph a hand',
    confirmation_en: 'Are you happy with this photo?',
    quote1_en: 'The whole hand',
    quote2_en: 'Including the palm, fingers, and thumb.',
    quote3_en: 'Why do hands shake?',
    instruction_nl: 'Maak een foto van een hand',
    confirmation_nl: 'Blij met deze foto?',
    quote1_nl: 'De hele hand',
    quote2_nl: 'Handpalm, vingers, duim',
    quote3_nl: 'Waarom schudt de hand - en schudden wij elkaar de hand?',
  },
  74: {
    groupId: 74,
    instruction_en: 'Photograph a massive being',
    confirmation_en: 'Are you happy with this massive being?',
    quote1_en: 'No need to run',
    quote2_en: 'Hey massive being, tell me what you see!',
    quote3_en: 'You make me feel small.',
    instruction_nl: 'Maak een foto van een groot wezen',
    confirmation_nl: 'Tevreden met dit grote schepsel?',
    quote1_nl: 'Wees maar niet bang',
    quote2_nl: 'Hee reus, wat zie jij vanaf daar?',
    quote3_nl: 'Ik voel me klein naast jou',
  },
  75: {
    groupId: 75,
    instruction_en: 'Photograph closed eyes',
    confirmation_en: 'Are you happy with this photo?',
    quote1_en: 'What do you see with closed eyes?',
    quote2_en: 'Nothing?',
    quote3_en: 'Really?',
    instruction_nl: 'Maak een foto van gesloten ogen',
    confirmation_nl: 'Blij met deze foto?',
    quote1_nl: 'Wat zie je met je ogen dicht?',
    quote2_nl: 'Zie je niets?',
    quote3_nl: 'Zie je echt niets?',
  },
  76: {
    groupId: 76,
    instruction_en: 'Take a photo of something that humans don’t love.',
    confirmation_en: 'Having a problem with this?',
    quote1_en: 'Mosquitoes',
    quote2_en: 'Lack of water',
    quote3_en: 'Being broke',
    instruction_nl: 'Maak een foto van iets waar mensen niet van houden',
    confirmation_nl: 'Heb je hier moeite mee?',
    quote1_nl: 'Muggen',
    quote2_nl: 'Watertekort',
    quote3_nl: 'Blut zijn',
  },
  77: {
    groupId: 77,
    instruction_en: 'Photograph water',
    confirmation_en: 'Are you happy with this photo?',
    quote1_en: 'Transparent, odourless liquid',
    quote2_en: 'That forms the seas, lakes, rivers and rain',
    quote3_en: 'The basis of the fluids of living organisms',
    instruction_nl: 'Maak een foto van water',
    confirmation_nl: 'Blij met deze foto?',
    quote1_nl: 'Transparante, geurloze vloeistof',
    quote2_nl: 'Waar de zeeën, meren, rivieren en de regen uit bestaan',
    quote3_nl: 'Glad, stromend of kolkend',
  },
  78: {
    groupId: 78,
    instruction_en: 'Photograph something that grows',
    confirmation_en: 'Are you happy with this photo?',
    quote1_en: 'The process of increasing in size',
    quote2_en: "Five o'clock shadow",
    quote3_en: 'What makes it grow?',
    instruction_nl: 'Maak een foto van iets dat groeit',
    confirmation_nl: 'Blij met deze foto?',
    quote1_nl: 'Het proces van toenemen in omvang',
    quote2_nl: "Five o'clock shadow",
    quote3_nl: 'Wat zorgt voor groei?',
  },
  79: {
    groupId: 79,
    instruction_en: 'Photograph something that wasn’t designed',
    confirmation_en: 'Are you happy with this photo?',
    quote1_en: 'Anything not designed within your reach now?',
    quote2_en: "Disasters can 'un-design' our surroundings",
    quote3_en: 'Does nature design too?',
    instruction_nl: 'Maak een foto van iets dat niet ontworpen is',
    confirmation_nl: 'Blij met deze foto?',
    quote1_nl: 'Is alles dat je nu bij de hand hebt ooit ontworpen?',
    quote2_nl: "Rampen kunnen onze omgeving van al 't ontwerp ontdoen",
    quote3_nl: 'Ontwerpt de natuur ook?',
  },
  80: {
    groupId: 80,
    instruction_en: 'Photograph a circle',
    confirmation_en: 'Are you happy with this circle',
    quote1_en: 'The top of a cup, the hole for the headset',
    quote2_en: "They're everywhere!",
    quote3_en: '(o) o • o (o) •',
    instruction_nl: 'Maak een foto van een cirkel',
    confirmation_nl: 'Ben je tevreden met deze cirkel?',
    quote1_nl: 'De opening van een theekopje, de aansluiting voor je koptelefoon',
    quote2_nl: 'Ze zijn overal!',
    quote3_nl: '(O) o • o (o) •',
  },
  81: {
    groupId: 81,
    instruction_en: 'Take a photo of a long, straight line',
    confirmation_en: 'Are you happy with this line?',
    quote1_en: 'A wrinkle in the skin',
    quote2_en: 'Piece of cord, rope or wire',
    quote3_en: 'Edge between two surfaces',
    instruction_nl: 'Maak een foto van een lange, rechte lijn',
    confirmation_nl: 'Blij met deze lijn?',
    quote1_nl: 'Een rimpel in de huid',
    quote2_nl: 'Een stuk touw, een kabel of draad',
    quote3_nl: 'Grensgebied tussen twee oppervlakken',
  },
  82: {
    groupId: 82,
    instruction_en: 'Photograph a mystery',
    confirmation_en: 'Are you happy with this photo?',
    quote1_en: 'Who knows what will happen?',
    quote2_en: 'Impossible to understand',
    quote3_en: 'Impossible to explain',
    instruction_nl: 'Maak een foto van een mysterie',
    confirmation_nl: 'Blij met deze foto?',
    quote1_nl: 'Wie weet wat er zal gebeuren?',
    quote2_nl: 'Onbegrijpelijk',
    quote3_nl: 'Niet uit te leggen',
  },
  83: {
    groupId: 83,
    instruction_en: 'Photograph two similair things',
    confirmation_en: 'Are you happy with this photo?',
    quote1_en: 'Similair is not identical',
    quote2_en: 'What’s the difference?',
    quote3_en: 'A horse can gave identical twins',
    instruction_nl: 'Maak een foto van twee vergelijkbare dingen',
    confirmation_nl: 'Blij met deze foto?',
    quote1_nl: 'Vergelijkbaar maar niet hetzelfde',
    quote2_nl: 'Wat is het verschil?',
    quote3_nl: 'Soms krijgt een paard een een-eiïge tweeling',
  },
  84: {
    groupId: 84,
    instruction_en: 'Photograph an arrow',
    confirmation_en: 'Are you happy with the direction?',
    quote1_en: 'Where are we going?',
    quote2_en: "I've been here before.",
    quote3_en: 'This is where we are',
    instruction_nl: 'Maak een foto van een pijl',
    confirmation_nl: 'Wijst-ie de goede kant op?',
    quote1_nl: 'Waar gaan we naartoe?',
    quote2_nl: 'Ik ben hier eerder geweest.',
    quote3_nl: 'Dit is waar wij ons nu bevinden',
  },
  85: {
    groupId: 85,
    instruction_en: 'Photograph a metal surface',
    confirmation_en: 'Are you happy with this photo?',
    quote1_en: 'Coated plastic?',
    quote2_en: 'Polished, brushed?',
    quote3_en: 'What purpose do surfaces have?',
    instruction_nl: 'Maak een foto van een metalen oppervlak',
    confirmation_nl: 'Blij met deze foto?',
    quote1_nl: 'Zit er plastic op?',
    quote2_nl: 'Gepolijst, geborsteld?',
    quote3_nl: 'Waar zijn oppervlakken voor?',
  },
  86: {
    groupId: 86,
    instruction_en: 'Take a photo of planet earth',
    confirmation_en: 'Are you happy with this photo?',
    quote1_en: 'The planet we live on',
    quote2_en: 'Who does the Earth belong to?',
    quote3_en: 'Beautiful, little blue pea',
    instruction_nl: 'Maak een foto van de planeet aarde',
    confirmation_nl: 'Blij met deze foto?',
    quote1_nl: 'De planeet waar we op leven',
    quote2_nl: 'Van wie is de aarde?',
    quote3_nl: 'Kleine, mooie, blauwe erwt',
  },
  87: {
    groupId: 87,
    instruction_en: 'Photograph the smoothest surface',
    confirmation_en: 'Are you happy with this photo?',
    quote1_en: 'Free from lumps or indentations',
    quote2_en: "What's smooth and what's rough?",
    quote3_en: 'and what’s the difference?',
    instruction_nl: 'Maak een foto van een glad oppervlak',
    confirmation_nl: 'Blij met deze foto?',
    quote1_nl: 'Zonder bulten of gaten',
    quote2_nl: 'Wat is glad en wat ruw?',
    quote3_nl: 'Glibbert het?',
  },
  88: {
    groupId: 88,
    instruction_en: 'Photograph something wrong',
    confirmation_en: 'Is this correct?',
    quote1_en: 'Sometimes it winks, sometimes it smiles',
    quote2_en: 'Should you fix it?',
    quote3_en: 'Not true, not correct',
    instruction_nl: 'Maak een foto van iets dat niet klopt',
    confirmation_nl: 'Klopt dit?',
    quote1_nl: "Soms knipoogt 't, soms geeft het een glimlach",
    quote2_nl: 'Moet je er iets aan doen?',
    quote3_nl: 'Niet waar, niet correct',
  },
  89: {
    groupId: 89,
    instruction_en: 'Photograph something dead or dying',
    confirmation_en: 'Are you happy with this photo?',
    quote1_en: 'No longer current, relevant, or important.',
    quote2_en: 'Was it alive?',
    quote3_en: 'The centre cannot hold',
    instruction_nl: 'Maak een foto van iets dat dood is of doodgaat',
    confirmation_nl: 'Blij met deze foto?',
    quote1_nl: 'Niet actueel, relevant of belangrijk meer',
    quote2_nl: 'Leefde het eerst wel?',
    quote3_nl: 'Kan iets ook een beetje dood zijn?',
  },
  90: {
    groupId: 90,
    instruction_en: 'Photograph the end',
    confirmation_en: 'Is this the end?',
    quote1_en: 'The final part of something',
    quote2_en: 'The most extreme part of something.',
    quote3_en: 'We should have seen this coming',
    instruction_nl: 'Maak een foto van het einde',
    confirmation_nl: 'Is dit het einde?',
    quote1_nl: 'Ergens het laatste deel van',
    quote2_nl: 'Ergens het buitenste deel van',
    quote3_nl: 'We hadden dit moeten zien aankomen',
  },
  91: {
    groupId: 91,
    instruction_en: 'Photograph a pattern',
    confirmation_en: 'Are you happy with this photo?',
    quote1_en: 'Everything loops',
    quote2_en: 'Again',
    quote3_en: 'On the bathroom floor',
    instruction_nl: 'Maak een foto van een patroon',
    confirmation_nl: 'Blij met deze foto?',
    quote1_nl: 'Alles herhaalt zich',
    quote2_nl: 'Nog een keer',
    quote3_nl: 'Op de badkamervloer',
  },
  92: {
    groupId: 92,
    instruction_en: 'Photograph repetition',
    confirmation_en: 'Are you happy with this photo?',
    quote1_en: 'Pulsing',
    quote2_en: 'Spinning',
    quote3_en: 'Trapped behaviour',
    instruction_nl: 'Maak een foto van een herhaling',
    confirmation_nl: 'Blij met deze foto?',
    quote1_nl: 'Pulseren',
    quote2_nl: 'Ronddraaien',
    quote3_nl: 'Opgesloten gedrag',
  },
  93: {
    groupId: 93,
    instruction_en: 'Photograph a tiled surface',
    confirmation_en: 'Are you happy with these tiles?',
    quote1_en: 'Checkerboard',
    quote2_en: 'Graph paper',
    quote3_en: 'Minecraft',
    instruction_nl: 'Maak een foto van een betegeld oppervlak',
    confirmation_nl: 'Ben je blij met deze tegels?',
    quote1_nl: 'Schaakbord',
    quote2_nl: 'Ruitjespapier',
    quote3_nl: 'Minecraft',
  },
  94: {
    groupId: 94,
    instruction_en: 'Photograph noise',
    confirmation_en: 'Are you happy with this photo?',
    quote1_en: 'Space made visible',
    quote2_en: 'Irregular fluctuations',
    quote3_en: 'It surrounds me',
    instruction_nl: 'Maak een foto van lawaai',
    confirmation_nl: 'Blij met deze foto?',
    quote1_nl: 'Zichtbaar gemaakte ruimte',
    quote2_nl: 'Onregelmatige schommelingen',
    quote3_nl: 'Het omringt me',
  },
  95: {
    groupId: 95,
    instruction_en: 'Photograph a wooden surface',
    confirmation_en: 'Is that wood?',
    quote1_en: 'The interior of a tree',
    quote2_en: 'Is het really wood?',
    quote3_en: 'Is it still a tree?',
    instruction_nl: 'Maak een foto van een houten oppervlak',
    confirmation_nl: 'Is dat hout?',
    quote1_nl: 'De binnenkant van een boom',
    quote2_nl: 'Is het echt hout?',
    quote3_nl: 'Is het nog boom?',
  },
  96: {
    groupId: 96,
    instruction_en: 'Photograph an animal',
    confirmation_en: 'Is that an animal?',
    quote1_en: 'It breathes?',
    quote2_en: 'Is it warm?',
    quote3_en: 'Does it move?',
    instruction_nl: 'Maak een foto van een dier',
    confirmation_nl: 'Is dat een dier?',
    quote1_nl: 'Ademt het?',
    quote2_nl: 'Is het warm?',
    quote3_nl: 'Beweegt het?',
  },
  97: {
    groupId: 97,
    instruction_en: 'Photograph something fluffy',
    confirmation_en: 'Are you happy with this photo?',
    quote1_en: "What's under that fuzz?",
    quote2_en: 'Hairy, feathery',
    quote3_en: 'downy, furry',
    instruction_nl: 'Maak een foto van iets pluizigs',
    confirmation_nl: 'Blij met deze foto?',
    quote1_nl: 'Wat zit er onder het pluis?',
    quote2_nl: 'Harig of met veren',
    quote3_nl: 'Donzig of ruwharig',
  },
  98: {
    groupId: 98,
    instruction_en: 'Photograph hairless skin',
    confirmation_en: 'Are you happy with this photo?',
    quote1_en: 'Your interface with the physical world',
    quote2_en: 'Capable of touch',
    quote3_en: 'Home of the hairshaft',
    instruction_nl: 'Maak een foto van een stukje huid zonder haar',
    confirmation_nl: 'Blij met deze foto?',
    quote1_nl: 'De laag tussen jezelf en de wereld',
    quote2_nl: 'Je kunt het aanraken en ermee aanraken',
    quote3_nl: 'Waar de haarzakjes in zitten',
  },
  99: {
    groupId: 99,
    instruction_en: 'Photograph the inside of an open mouth',
    confirmation_en: 'Are you happy with this photo?',
    quote1_en: 'Open sesame',
    quote2_en: 'Home for your tongue',
    quote3_en: 'Food goes in words come out',
    instruction_nl: 'Maak een foto van de binnenkant van een open mond',
    confirmation_nl: 'Blij met deze foto?',
    quote1_nl: 'Sesam open u',
    quote2_nl: 'Het huis van je tong',
    quote3_nl: 'Er gaat eten in en er komen woorden uit',
  },
  100: {
    groupId: 100,
    instruction_en: 'Photograph a dirty surface',
    confirmation_en: 'Call that dirty?',
    quote1_en: 'Disgusting!',
    quote2_en: 'It was me',
    quote3_en: 'Smartphone screen',
    instruction_nl: 'Maak een foto van een vies oppervlak',
    confirmation_nl: 'Noem je dit vies?',
    quote1_nl: 'He bah!',
    quote2_nl: 'Was mij',
    quote3_nl: 'Het scherm van je smartphone',
  },
  101: {
    groupId: 101,
    instruction_en: 'Photograph a close-up of a plant',
    confirmation_en: 'Are you happy with this photo?',
    quote1_en: 'Mostly green',
    quote2_en: 'Plants communicate too',
    quote3_en: 'Or make a drawing in Neulab with a plant',
    instruction_nl: 'Maak een close-upfoto van een plant',
    confirmation_nl: 'Blij met deze foto?',
    quote1_nl: 'Meestal groen',
    quote2_nl: 'Planten communiceren ook',
    quote3_nl: 'Of maak samen met een plant een tekening in Neulab',
  },
  102: {
    groupId: 102,
    instruction_en: 'Photograph an organic substance',
    confirmation_en: 'Are you happy with this photo?',
    quote1_en: 'Substance is a bore',
    quote2_en: 'Is it a bodily organ?',
    quote3_en: 'What is that?',
    instruction_nl: 'Maak een foto van een organische substantie',
    confirmation_nl: 'Blij met deze foto?',
    quote1_nl: 'Substantie is saai',
    quote2_nl: 'Is het een orgaan?',
    quote3_nl: 'Wat is dat?',
  },
}

export default instructions
