import * as Sentry from '@sentry/browser'

async function detectCameraPermission() {
  let hasPermission = false
  if (navigator.permissions) {
    try {
      const { state } = await navigator.permissions.query({ name: 'camera' })
      if (state === 'granted') {
        hasPermission = true
      }
    } catch (error) {
      // do nothing
      console.error('Can not access camera', error)
    }
  }

  Sentry.configureScope(scope => {
    scope.setExtra('hasCameraPermission', hasPermission)
  })

  return hasPermission
}

export default detectCameraPermission
