import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './Overlay.scss'
import CloseButton from '../Buttons/CloseButton'

const Overlay = ({
  close, children, onClose, className,
}) => (
  <div className={classNames('Overlay', className)}>
    {close && <CloseButton onTap={onClose} />}
    <div className="OverlayContent">{children}</div>
  </div>
)

Overlay.propTypes = {
  children: PropTypes.node.isRequired,
  close: PropTypes.bool,
  onClose: PropTypes.func,
  className: PropTypes.string,
}

Overlay.defaultProps = {
  close: false,
  onClose: () => {},
  className: '',
}

export default Overlay
