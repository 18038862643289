import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './TextAnimation.scss'
import Translateable from '../locale/Translatable'

class TextAnimation extends Component {
  state = {}

  componentDidMount() {
    const { textIds, link } = this.props
    const ids = [...textIds, false]
    this.timeouts = ids.map((item, idx) => setTimeout(() => {
      if (item === false && link) {
        this.setState({
          key: 'link',
        })
        setTimeout(() => {
          this.props.onFinished()
        }, 2000)
      } else if (item === false) {
        this.props.onFinished()
      } else {
        this.setState({
          key: item,
        })
      }
    }, idx * 2000))
  }

  componentWillUnmount() {
    if (this.timeouts) this.timeouts.forEach(clearTimeout)
  }

  render() {
    const { key } = this.state
    return key ? (
      <div className="IntroTextAnimation">
        {key === 'link' && (
          <>
            {'neuhaus.world/'}
            <br />
            {window.location.pathname.replace(/\//gi, '')}
          </>
        )}
        {key !== 'link' && <Translateable id={this.state.key} />}
      </div>
    ) : null
  }
}

TextAnimation.propTypes = {
  textIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  onFinished: PropTypes.func,
  link: PropTypes.bool,
}

TextAnimation.defaultProps = {
  onFinished: () => {},
  link: false,
}

export default TextAnimation
