import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './About.scss'
import CloseButton from '../Buttons/CloseButton'
import Translateable from '../locale/Translatable'
import AboutTopContainer from '../../containers/AboutTopContainer'
import VersionBarContainer from '../../containers/VersionBarContainer'

const About = ({ onClose, isUserVersion }) => (
  <div className="About">
    <CloseButton
      onTap={() => {
        // TODO: there seems to be a bug on desktop
        // not closing about page sometimes, has to do with Tappable:
        // mouseout being called before mouseup
        onClose()
      }}
    />

    <AboutTopContainer />

    <div className={classNames('AboutContent', !isUserVersion && 'has-bar')}>
      <div className="content-big">
        <Translateable id="about.short" />
      </div>
      <div className="columns">
        <div className="column">
          <section>
            <Translateable id="about.long" />
          </section>
          <section className="desktop-only">
            <Translateable id="about.press" />
          </section>
          <section className="desktop-only">
            <Translateable id="about.credits" />
          </section>
        </div>
        <div className="column">
          <section>
            <Translateable id="about.faq" />
          </section>
          <section>
            <Translateable id="about.technology" />
          </section>
          <section className="not-desktop">
            <Translateable id="about.press" />
          </section>
          <section className="not-desktop">
            <Translateable id="about.credits" />
          </section>
        </div>
      </div>
    </div>
    <VersionBarContainer background />
  </div>
)

About.propTypes = {
  onClose: PropTypes.func.isRequired,
  isUserVersion: PropTypes.bool.isRequired,
}

export default About
