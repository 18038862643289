import * as Sentry from '@sentry/browser'
import * as THREE from 'three'

import store from '../store'
import { texturesQueued, texturesLoaded, texturesDestroyed } from '../actions/version'
import ObjectMap from './ObjectMap'
import { applyBaseMaterial } from '../animation/utils/applyBaseMaterial'
import TextureLoader from './TextureLoader'

const loader = new TextureLoader()

class TextureManager {
  showDebugMessages = false
  isLoading = false
  frame = -1
  loaded = []
  queue = []
  prevQueueLength = undefined
  loading = {}

  constructor() {
    this.destroyTexture = this.destroyTexture.bind(this)
    this.addToQueue = this.addToQueue.bind(this)
    this.cancelTexture = this.cancelTexture.bind(this)
  }

  init(scene, points) {
    this.reset()
    this.setInOutPoints(points)
    this.setScene(scene)
  }

  setInOutPoints(timeline) {
    this.timeline = timeline
  }

  setTextures(textures) {
    this.textures = textures
  }

  setScene(scene) {
    this.scene = scene
  }

  addToQueue(name) {
    if (!this.queue.includes(name) && !this.loaded.includes(name)) {
      this.queue.push(name)
    }
  }

  async applyTexture(objectName) {
    const obj = ObjectMap.getObjectByName(objectName)
    const image = this.textures[objectName]
    if (obj && image) {
      try {
        this.isLoading = true
        const texture = await this.loadTexture(objectName, image)
        const side = objectName.split('_')[0] === 'ds' ? THREE.DoubleSide : THREE.FrontSide
        const material = new THREE.MeshBasicMaterial({
          map: texture,
          side,
        })
        obj.material = material

        this.loaded.push(objectName)
        this.isLoading = false
      } catch (error) {
        // if we would have cancelled the request it would not be in the loading list
        if (this.loading[objectName]) {
          delete this.loading[objectName]
          console.error('error loading texture', error)
          Sentry.captureException(error)
        }
      }
    } else {
      // console.info('no object or image for', objectName)
      // this.queue.push(objectName)
    }

    if (this.queue.length === 0 && this.showDebugMessages) {
      console.log('currently', this.loaded.length, 'textures loaded')
    }
  }

  loadTexture(objectName, image) {
    return new Promise((resolve, reject) => {
      loader.load(
        image,
        texture => {
          delete this.loading[objectName]
          texture.flipY = false
          resolve(texture)
        },
        undefined,
        err => {
          this.isLoading = false
          reject(err)
        },
      )
      this.loading[objectName] = loader.image
    })
  }

  cancelTexture(name) {
    if (this.loading[name]) {
      this.loading[name].src = ''
      delete this.loading[name]
    }
  }

  destroyTexture(name) {
    if (this.loaded.includes(name)) {
      const obj = ObjectMap.getObjectByName(name)
      if (obj) {
        if (this.showDebugMessages) console.log('destroying texture', name)
        // only destroy when we actually have a texture
        if (obj.material.map) {
          obj.material.map.dispose()
          applyBaseMaterial(obj)
        }
        // obj.material.dispose()
        // obj.material = undefined
        // obj.geometry.dispose()
        // obj.geometry = undefined
        // this.scene.remove(obj)
      }

      const loadedIndex = this.loaded.indexOf(name)
      if (loadedIndex > -1) this.loaded.splice(loadedIndex, 1)
      const queueIndex = this.queue.indexOf(name)
      if (queueIndex > -1) this.queue.splice(queueIndex, 1)
    }
  }

  getActions(frame) {
    // in case we go back in time we need load all actions to calculate the state
    const since = frame > this.frame ? this.frame : -1

    // find all events between two frames
    const events = Object.keys(this.timeline)
      .map(Number)
      .filter(t => t >= since && t <= frame)

    // reduce events to an array of items to load and destroy
    const actions = events.reduce(
      (acc, cur) => {
        const time = this.timeline[cur]
        if (time.in) acc.load.push(...time.in)
        if (time.out) acc.destroy.push(...time.out)
        return acc
      },
      { load: [], destroy: [] },
    )

    const toLoad = actions.load.filter(
      // don't load items that should be destroyed or are already loaded
      name => !actions.destroy.includes(name) && !this.loaded.includes(name) && this.textures[name],
    )

    // don't destroy items that should be loaded
    let toDestroy = actions.destroy.filter(
      // name => !toLoad.includes(name) && this.loaded.includes(name) && this.textures[name],
      name => !toLoad.includes(name),
    )

    const toCancel = toDestroy.filter(name => this.loading[name])

    // in case we go back in time actions.load contains all objects that are in view at that time
    // we need to destroy all objects that are not in that list
    if (since === -1) {
      toDestroy = this.loaded.filter(l => !actions.load.includes(l))
    }

    return {
      load: toLoad,
      destroy: toDestroy,
      cancel: toCancel,
    }
  }

  update(frame, shouldTick = true, force = false) {
    if (!this.textures || !this.timeline) return
    if (frame !== this.frame || force) {
      const { load, destroy, cancel } = this.getActions(frame)
      load.forEach(this.addToQueue)
      destroy.forEach(this.destroyTexture)
      cancel.forEach(this.cancelTexture)
      this.frame = frame

      if (load.length > 0) store.dispatch(texturesQueued(load.length))
      if (destroy.length > 0) store.dispatch(texturesDestroyed(destroy.length))
    }

    if (this.queue.length === 0 && this.queue.length !== this.prevQueueLength) {
      store.dispatch(texturesLoaded(this.loaded.length))
    }
    this.prevQueueLength = this.queue.length

    if (shouldTick) this.tick()
  }

  tick() {
    if (!this.isLoading && this.queue.length) {
      this.applyTexture(this.queue.shift())
    }
  }

  reset(resetTimeline = false) {
    Object.keys(this.loading).forEach(this.cancelTexture)
    this.loaded.forEach(this.destroyTexture)
    if (resetTimeline) this.timeline = {}
    this.isLoading = false
    this.frame = -1
    this.loaded = []
    this.loading = {}
    this.queue = []
  }

  animateOut(time = 100) {
    if (this.loaded.length === 0) return
    return new Promise(resolve => {
      let i = 0
      Object.keys(this.loading).forEach(this.cancelTexture)
      this.loaded.forEach(loaded => {
        setTimeout(() => {
          this.destroyTexture(loaded)
          if (this.loaded.length === 0) return resolve()
        }, i * time)
        i += 1
      })
    })
  }
}

export default new TextureManager()
