import React from 'react'
import SvgButton from './SvgButton'

const CloseButton = props => (
  <SvgButton id="Close" {...props}>
    <svg width="16.359" height="16.359" viewBox="0 0 16.359 16.359">
      <path strokeWidth="1.8px" className="a" d="M.637 15.723L15.723.637M15.722 15.722L.638.637" />
    </svg>
  </SvgButton>
)

export default CloseButton
