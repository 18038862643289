import * as THREE from 'three'

import sceneLoader from './utils/sceneLoader'
import settings from '../settings'
import { applyBaseMaterials } from './utils/applyBaseMaterial'
import Camera from './Camera'
import SubScene from './SubScene'
import Renderer from './Renderer'
import SubSceneMixer from './SubSceneMixer'
import WebcamScene from './WebcamScene'
import AnimationController from './AnimationController'

class Scene {
  async init() {
    await this.load()

    return this.scene
  }

  async load() {
    const { scene, cameras, animations } = await sceneLoader(settings.animation.scene)

    this.camera = Camera.init(cameras[0])

    this.setupScene(scene)
    this.scene = scene

    this.controller = AnimationController
    this.controller.init(animations, scene)
  }

  setupScene(scene) {
    applyBaseMaterials(scene)

    const fogColor = new THREE.Color(0x000000)
    scene.background = fogColor
    // scene.fog = new THREE.Fog(fogColor, 0, 100)

    // only works with test textures
    // forEachChildRecursive(this.scene.getObjectByName('Ball_null'), c => {
    //   TextureManager.applyTexture(c.name)
    // })

    this.mixer = this.createSceneMixer(scene)
  }

  createSceneMixer(scene) {
    const mainScene = new SubScene({
      camera: Camera.get(),
      renderer: Renderer.get(),
      clearColor: 0xffffff,
    })
    mainScene.setScene(scene)

    WebcamScene.init(Camera.get(), Renderer.get())
    return new SubSceneMixer(Renderer.get(), mainScene, WebcamScene.sub)
  }
}

export default new Scene()
