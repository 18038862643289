import * as THREE from 'three'
import forEachChildRecursive from './forEachChildRecursive'

const color = new THREE.Color()

export const applyBaseMaterial = obj => {
  if (obj.material) obj.material.dispose()
  const side = obj.name.split('_')[0] === 'fs' ? THREE.FrontSide : THREE.DoubleSide
  const material = new THREE.MeshBasicMaterial({
    side,
    color: color.setRGB(
      0.05 + Math.random() * 0.95,
      0.05 + Math.random() * 0.95,
      0.05 + Math.random() * 0.95,
    ),
  })
  obj.material = material
}

export const applyBaseMaterials = scene => {
  forEachChildRecursive(scene, obj => {
    if (obj.type === 'Mesh') {
      applyBaseMaterial(obj)
    }
  })
}
