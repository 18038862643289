import React from 'react'
import SvgButton from './SvgButton'

const AboutButton = props => (
  <SvgButton id="About" {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38">
      <g transform="translate(-331 -76.129)">
        <ellipse cx="19" cy="19" rx="19" ry="19" transform="translate(331 76.129)" />
        <path
          d="M349.706 107.239a2 2 0 1 0-2.009-1.981 2.005 2.005 0 0 0 2.009 1.981zm-1.306-6.291h2.46v-1.345c0-4.153 5.751-4.824 5.751-9.808 0-4.121-2.748-6.294-6.518-6.294-4.057 0-6.387 2.647-6.387 6.647h2.78c0-1.118.288-4.249 3.674-4.249 3.131 0 3.674 2.46 3.674 3.8 0 3.77-5.431 4.217-5.431 9.9z"
          opacity=".9"
        />
      </g>
    </svg>
  </SvgButton>
)

export default AboutButton
