import React, { Component } from 'react'
import classNames from 'classnames'

import './TitleSequence.scss'
import PlaybackController from '../../utils/PlaybackController'
import Translateable from '../locale/Translatable'

class TitleSequence extends Component {
  state = {
    values: ['title.1', 'title.2', 'title.3', null],
    keyframes: [1, 310, 610, 910],
    frame: PlaybackController.frame,
    selected: 3,
  }

  constructor(props) {
    super(props)

    this.updateFrame = this.updateFrame.bind(this)

    PlaybackController.on('frame', this.updateFrame)
  }

  componentDidMount() {
    this.mounted = true
  }

  shouldComponentUpdate(nextProps, { selected }) {
    return selected !== this.state.selected
  }

  componentWillUnmount() {
    this.mounted = false
  }

  updateFrame(frame) {
    if (this.mounted && frame !== this.state.frame) {
      let { selected } = this.state
      this.state.keyframes.forEach((f, idx) => {
        const next = this.state.keyframes[idx + 1] || undefined
        if (frame >= f && (frame <= next || !next)) {
          selected = idx
        }
      })
      this.setState({
        frame,
        selected,
      })
    }
  }

  render() {
    const { selected, values } = this.state
    const id = values[selected]
    const className = classNames('TitleSequence', {
      hidden: !id,
      visible: !!id,
    })
    return <div className={className}>{id && <Translateable id={id} />}</div>
  }
}

export default TitleSequence
