import * as THREE from 'three'

import SubScene from './SubScene'

class WebcamScene {
  constructor() {
    this.clear = this.clear.bind(this)
  }

  init(camera, renderer) {
    this.scene = new THREE.Scene()

    this.sub = new SubScene({
      camera,
      renderer,
      clearColor: 0x000000,
    })
    this.sub.setScene(this.scene)
  }

  setVideoTexture(canvasElement) {
    this.canvasElement = canvasElement
    if (this.canvasTexture) {
      // TODO: get rid of old one?
    }
    this.canvasTexture = new THREE.CanvasTexture(canvasElement)
    this.canvasTexture.flipY = false
    this.canvasTexture.wrapS = THREE.RepeatWrapping
    this.canvasTexture.repeat.set(1, 1)

    this.canvasMaterial = new THREE.MeshBasicMaterial({
      map: this.canvasTexture,
      side: THREE.DoubleSide,
    })

    // in case the camera is active we need to update the current material
    if (this.selectedObject) {
      this.selectedObject.material = this.canvasMaterial
    }
  }

  add(object, selectedObject) {
    this.scene.add(object)
    this.isUsed = true
    this.selectedObject = selectedObject
  }

  clear() {
    this.scene.children.forEach(obj => {
      if (obj.type === 'Mesh' && obj.material) {
        obj.material.dispose()
        obj.geometry.dispose()
      }
      obj.parent.remove(obj)
    })

    this.isUsed = false
    this.selectedObject = undefined
  }
}

export default new WebcamScene()
