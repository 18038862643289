import instructions from '../assets/instructions'
import mapping from '../assets/mapping'

const initialState = {
  instructions,
  mapping,
}

export default (state = initialState, action) => {
  if (state.hydrated === true) {
    const newState = {
      ...initialState,
      ...state,
      hydrated: false,
    }

    return newState
  }

  switch (action.type) {
    default:
      return state
  }
}
