import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Tappable from 'react-tappable/lib/Tappable'

import './Assignment.scss'
import Translateable from '../locale/Translatable'
import CloseButton from '../Buttons/CloseButton'
import CameraButtons from './CameraButtons'
import Quotes from './Quotes'
import PermissionPrompt from '../PermissionPrompt/PermissionPrompt'
import Spinner from '../Spinner/Spinner'

class Assignment extends Component {
  state = {
    showPermissionPrompt: false,
  }

  constructor(props) {
    super(props)

    this.handleConfirm = this.handleConfirm.bind(this)
    this.handlePermission = this.handlePermission.bind(this)
    this.handleDecline = this.handleDecline.bind(this)
  }

  handleConfirm() {
    const { didAgree, onConfirm } = this.props
    if (didAgree) {
      onConfirm(this.state.didDecline)
    } else {
      this.setState({ showPermissionPrompt: true })
    }
  }

  handlePermission() {
    this.setState({ showPermissionPrompt: false })
    this.props.onConfirm()
    this.props.userAgreed()
  }

  handleDecline() {
    this.setState({ showPermissionPrompt: false })
  }

  render() {
    const {
      isUploading,
      isConfirming,
      onClose,
      onCapture,
      onCancel,
      facingMode,
      toggleFacingMode,
      instruction,
      presentation,
    } = this.props

    return (
      <div className="Assignment">
        {!isUploading && !this.state.showPermissionPrompt && <CloseButton onTap={onClose} />}

        {!isUploading
          && !isConfirming && (
            <>
              {instruction
                && !presentation && <div className="instruction">{instruction.instruction}</div>}
              <CameraButtons
                takePicture={onCapture}
                toggleFacingMode={toggleFacingMode}
                facingMode={facingMode}
              />
              {instruction && !presentation && <Quotes instruction={instruction} />}
            </>
        )}

        {isUploading
          && !presentation
          && !this.state.showPermissionPrompt && (
            <>
              <span className="instruction">
                <Translateable id="assignment.uploading" />
              </span>
              <Spinner />
            </>
        )}

        {this.state.showPermissionPrompt && (
          <PermissionPrompt onPermission={this.handlePermission} onCancel={this.handleDecline} />
        )}

        {!isUploading
          && !this.state.showPermissionPrompt
          && isConfirming && (
            <>
              {!presentation
                && instruction && <div className="confirmation-text">{instruction.confirmation}</div>}
              <div className="confirmation-buttons">
                <Tappable
                  component="button"
                  onTap={this.handleConfirm}
                  className="confirmation-button"
                >
                  <Translateable id="yes" />
                </Tappable>

                <Tappable component="button" onTap={onCancel} className="confirmation-button">
                  <Translateable id="no" />
                </Tappable>
              </div>
            </>
        )}
      </div>
    )
  }
}

Assignment.propTypes = {
  didAgree: PropTypes.bool.isRequired,
  isUploading: PropTypes.bool.isRequired,
  isConfirming: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCapture: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  toggleFacingMode: PropTypes.func.isRequired,
  userAgreed: PropTypes.func.isRequired,
  facingMode: PropTypes.string,
  instruction: PropTypes.shape({
    instruction: PropTypes.string.isRequired,
    confirmation: PropTypes.string.isRequired,
  }),
  presentation: PropTypes.bool,
}

Assignment.defaultProps = {
  facingMode: 'environment',
  instruction: null,
  presentation: false,
}

export default Assignment
