import React from 'react'
import SvgButton from './SvgButton'

const ShareRoundButton = props => (
  <SvgButton id="ShareRound" {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38">
      <g transform="translate(-331 -129)">
        <circle cx="19" cy="19" r="19" transform="translate(331 129)" />
        <path d="M357.519 154.821h-16.355v-14.26h8.267v-1.9h-10.167v18.055h20.152v-5.161h-1.9z" />
        <path d="M345.653 150.519v.751h1.9v-.751c0-3.5 2.01-5.515 5.514-5.515h4.444v3.586l5.989-4.537-5.989-4.532v3.585h-4.444c-4.574.001-7.414 2.841-7.414 7.413z" />
      </g>
    </svg>
  </SvgButton>
)

export default ShareRoundButton
