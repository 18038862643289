import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './WelcomeScreen.scss'
import Translateable from '../locale/Translatable'
import TextAnimation from './TextAnimation'
import LabelButton from '../Buttons/LabelButton'
import slugToTitle from '../../utils/slugToTitle'
import ShareBar from '../ShareBar/ShareBar'
import VersionBarContainer from '../../containers/VersionBarContainer'

class WelcomeScreen extends Component {
  state = {
    isNewVersion: false,
    isAnimationFinished: false,
  }

  componentDidMount() {
    // automatically start playing in case of the HNI installation
    if (window.location.pathname.replace(/\//gi, '') === 'hni') {
      this.props.start()
    }
  }

  default() {
    const { start, version } = this.props
    const title = version === 'frontpage' ? '' : slugToTitle(version)
    const isLongTitle = title && title.split(' ').length >= 4

    return (
      <div className="WelcomeScreen">
        <div className="Enter">
          <div className="title">
            <h1 className={classNames(isLongTitle && 'long-title')}>
              <Translateable id="welcome" />
            </h1>

            <p className="subtitle">
              {title && <Translateable id="welcome.subtitle" variables={{ name: title }} />}
              {!title && <>&nbsp;</>}
            </p>
          </div>
          <LabelButton label="start" onTap={start} />
        </div>
        <VersionBarContainer
          onCreate={() => {
            this.setState({ isNewVersion: true, isAnimationFinished: false })
          }}
        />
      </div>
    )
  }

  newVersion() {
    const { start, shareNatively } = this.props
    const textIds = ['new.step1', 'new.step2', 'new.step3', 'new.step4', 'new.step5']
    return (
      <div className="WelcomeScreen new-version">
        <div className="Enter">
          {!this.state.isAnimationFinished && (
            <TextAnimation
              textIds={textIds}
              link
              onFinished={() => {
                this.setState({ isAnimationFinished: true })
              }}
            />
          )}
          {this.state.isAnimationFinished && <LabelButton label="start" onTap={start} />}
        </div>

        {this.state.isAnimationFinished && <ShareBar shareNatively={shareNatively} />}
      </div>
    )
  }

  render() {
    return this.state.isNewVersion || this.props.isNewVersion ? this.newVersion() : this.default()
  }
}

WelcomeScreen.propTypes = {
  start: PropTypes.func.isRequired,
  shareNatively: PropTypes.func.isRequired,
  version: PropTypes.string.isRequired,
  isNewVersion: PropTypes.bool,
}

WelcomeScreen.defaultProps = {
  isNewVersion: false,
}

export default WelcomeScreen
