import React, { Component } from 'react'
import device from 'current-device'
import classNames from 'classnames'

import './Timeline.scss'

import Page from '../../utils/Page'
import PlaybackController from '../../utils/PlaybackController'
import settings from '../../settings'

class Timeline extends Component {
  constructor(props) {
    super(props)

    this.updateProgress = this.updateProgress.bind(this)
    this.handleMouseMove = this.handleMouseMove.bind(this)
    this.handleMouseEnter = this.handleMouseEnter.bind(this)
    this.handleMouseLeave = this.handleMouseLeave.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.progressRef = React.createRef()
  }

  state = {
    progress: 0,
    frame: PlaybackController.frame,
  }

  componentDidMount() {
    PlaybackController.on('frame', this.updateProgress)
  }

  componentWillUnmount() {
    PlaybackController.off('frame', this.updateProgress)
  }

  updateProgress(force = false) {
    const { progress, isHovering } = this.state
    if (force === true || (!isHovering && progress !== PlaybackController.progress)) {
      this.progressRef.current.style.width = `${PlaybackController.progress * 100}%`
      this.setState({
        progress,
        frame: PlaybackController.frame,
      })
    }
  }

  handleMouseMove(e) {
    const p = e.clientX / Page.width
    this.progressRef.current.style.width = `${p * 100}%`
  }

  handleMouseEnter() {
    if (device.desktop()) {
      this.setState({
        isHovering: true,
      })
    }
  }

  handleMouseLeave() {
    if (device.desktop()) {
      this.setState({
        isHovering: false,
      })
      this.updateProgress(true)
    }
  }

  handleClick(e) {
    const p = e.clientX / Page.width
    const newTime = p * PlaybackController.duration
    if (PlaybackController.isFinished()) {
      PlaybackController.restart()
    }
    PlaybackController.gotoTime(newTime)
  }

  render() {
    const { frame } = this.state
    const isHidden = !device.desktop() && frame >= settings.times.credits[1] - 100
    const className = classNames('Timeline', isHidden && 'hidden')
    return (
      <div className={className}>
        <div
          className="progress-container"
          onMouseMove={this.handleMouseMove}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          onMouseUp={this.handleClick}
        >
          <div className="progress" ref={this.progressRef} />
        </div>
      </div>
    )
  }
}

export default Timeline
