import * as THREE from 'three'

import Page from '../utils/Page'

class SubScene {
  constructor({ camera = null, renderer, clearColor } = {}) {
    this.camera = camera
    this.renderer = renderer
    this.clearColor = clearColor
    this.scene = new THREE.Scene()

    const renderTargetParameters = {
      minFilter: THREE.LinearFilter,
      magFilter: THREE.LinearFilter,
      format: THREE.RGBFormat,
      stencilBuffer: false,
      // antialias: true,
    }

    this.fbo = new THREE.WebGLRenderTarget(Page.width, Page.height, renderTargetParameters)

    Page.on('resize', this.handleResize)
  }

  handleResize = ({ width, height }) => {
    this.fbo.setSize(width, height)
  }

  setScene(scene) {
    this.scene = scene
  }

  setCamera(camera) {
    this.camera = camera
  }

  render(delta, rtt) {
    this.renderer.setClearColor(this.clearColor, 1)

    if (rtt) {
      this.renderer.render(this.scene, this.camera, this.fbo, true)
    } else {
      this.renderer.render(this.scene, this.camera)
    }
  }
}

export default SubScene
