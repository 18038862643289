import React, { Component } from 'react'

import './Viewer.scss'
import viewer from '../../viewer'

export default class Viewer extends Component {
  constructor(props) {
    super(props)

    this.viewer = React.createRef()
  }

  async componentDidMount() {
    viewer.init(this.viewer.current)
  }

  render() {
    return (
      <div className="Viewer">
        <canvas ref={this.viewer} />
      </div>
    )
  }
}
