import * as Sentry from '@sentry/browser'
import axios from 'axios'
import ReactGA from 'react-ga'

import constants from './constants'
import TextureManager from '../utils/TextureManager'
import PlaybackController from '../utils/PlaybackController'
import { saveUser } from './user'
import history from '../utils/history'

const { REACT_APP_API_URL, REACT_APP_TEXTURES_URL } = process.env

export const versionLoaded = ({
  id, user, textures, name,
}) => async dispatch => {
  await dispatch({
    type: constants.VERSION_SUCCESS,
    data: {
      id,
      textures,
      name,
      user,
    },
  })


  if (PlaybackController.isPlaying) PlaybackController.pause({ fade: 0 })
  await TextureManager.animateOut(0)
  TextureManager.reset()

  // send them to the texture manager
  TextureManager.setTextures(textures)
  TextureManager.update(PlaybackController.frame, true, true)

  dispatch(saveUser())
}

export const loadVersion = (name = 'frontpage') => async dispatch => {
  dispatch({ type: constants.VERSION_REQUEST })
  try {
    // TODO: handle non existing versions
    const { data } = await axios.get(`${REACT_APP_API_URL}/versions/${name}`)
    const mapping = name === 'frontpage' ? data : data.mapping

    // prepend textures url to filename
    const textures = Object.keys(mapping).reduce((acc, cur) => {
      acc[cur] = `${REACT_APP_TEXTURES_URL}/${mapping[cur]}`
      return acc
    }, {})

    return dispatch(
      versionLoaded({
        id: data.id,
        textures,
        name,
        user: data.user,
      }),
    )
  } catch (error) {
    if (window.location.pathname.replace(/\//gi, '') !== 'hni') {
      console.error('error loading version', error)
      Sentry.captureException(error)
      dispatch({ type: constants.VERSION_ERROR, error })
    }
  }
}

// Textures part
export const texturesQueued = () => dispatch => {
  dispatch({ type: constants.TEXTURES_QUEUED })
}

export const texturesLoaded = totalLoaded => dispatch => {
  dispatch({ type: constants.TEXTURES_LOADED, data: { totalLoaded } })
}

export const texturesDestroyed = num => dispatch => {
  dispatch({ type: constants.TEXTURES_DESTROYED, data: { num } })
}

export const createNewVersion = () => async (dispatch, getState) => {
  TextureManager.animateOut()
  const {
    user: { id },
  } = getState()

  PlaybackController.reset()
  dispatch({ type: constants.RESTART_APP })
  dispatch({ type: constants.CREATE_NEW_VERSION })

  try {
    const { data } = await axios.post(`${REACT_APP_API_URL}/versions`, { user: id })
    dispatch(versionLoaded({ ...data, textures: {} }))
    ReactGA.event({
      category: 'version',
      action: 'new',
    })
    history.push(`/${data.name}`)
  } catch (error) {
    console.error('error creating new version', error)
    Sentry.captureException(error)
  }
}
