import timeline from './assets/timeline.json'

export default {
  isMuted: process.env.NODE_ENV === 'development',
  animation: {
    inOutPoints: timeline,
    // scene: process.env.REACT_APP_TEXTURES_URL.replace('images', 'scene.glb'), // for S3 hosting
    scene: `${process.env.PUBLIC_URL}/scene-2019-04-15.glb`,
  },
  times: {
    credits: [12800, 14900],
    tapHint: [1220],
    timeline: [13800],
    audioHint: [910, 1160],
    desktopHint: [910, 3200],
  },
}
