import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import * as Sentry from '@sentry/browser'
import ReactGA from 'react-ga'

import './index.scss'
import * as serviceWorker from './serviceWorker'
import store from './store'
import AppContainer from './containers/AppContainer'
import Page from './utils/Page'

import './audio'

if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize('UA-32525606-20')
  ReactGA.set({ anonymizeIp: true })
}

Sentry.init({
  dsn: 'https://65940c3d3a424fd480a2eae1aa84754c@sentry.dev.studiomoniker.com/8',
  beforeSend: (event, hint) => {
    if (process.env.NODE_ENV === 'development') {
      console.error(hint.originalException || hint.syntheticException)
      return null // this drops the event and nothing will be send to sentry
    }
    return event
  },
})

ReactDOM.render(
  <Provider store={store}>
    <AppContainer />
  </Provider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()

// make sure content does not disappear behind nav bar on iOS
if (Page.isIos) {
  let isTouching = false
  const body = document.querySelector('body')
  window.addEventListener('scroll', () => {
    if (Page.aspect > 1 && !isTouching && window.innerHeight < body.clientHeight) {
      window.scrollTo(0, 1)
    }
  })

  document.addEventListener('touchmove', () => {
    isTouching = true
  })

  document.addEventListener('touchend', () => {
    isTouching = false
    if (Page.aspect > 1 && window.innerHeight < body.clientHeight) {
      window.scrollTo(0, 1)
    }
  })
}

if (Page.isIos) {
  document.addEventListener(
    'touchmove',
    e => {
      if (e.scale !== 1) e.preventDefault()
    },
    { passive: false },
  )
}
