import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './Start.scss'
import TextAnimation from './TextAnimation'
import WelcomeScreen from './WelcomeScreen'
import Error from '../Error/Error'

const Start = ({
  isFinishedLoading, start, hasErrors, version, shareNatively, isNewVersion,
}) => {
  const classes = classNames('Start', isFinishedLoading && 'loaded', hasErrors && 'error')
  const textIds = ['intro.1', 'intro.2', 'intro.3', 'intro.4']
  return (
    <div className={classes}>
      {isFinishedLoading
        && !hasErrors && (
          <WelcomeScreen
            isNewVersion={isNewVersion}
            start={start}
            version={version}
            shareNatively={shareNatively}
          />
      )}
      {!isFinishedLoading && !hasErrors && <TextAnimation textIds={textIds} />}
      {hasErrors && <Error messageId="error.loading" />}
    </div>
  )
}

Start.propTypes = {
  isFinishedLoading: PropTypes.bool.isRequired,
  start: PropTypes.func.isRequired,
  shareNatively: PropTypes.func.isRequired,
  hasErrors: PropTypes.bool.isRequired,
  version: PropTypes.string,
  isNewVersion: PropTypes.bool,
}

Start.defaultProps = {
  version: null,
  isNewVersion: false,
}

export default Start
