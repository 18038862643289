import GLTFLoader from 'three-gltf-loader'

import store from '../../store'
import constants from '../../actions/constants'

const loader = url => new Promise((resolve, reject) => {
  store.dispatch({ type: constants.GLTF_REQUEST })
  new GLTFLoader().load(
    url,
    gltf => {
      store.dispatch({ type: constants.GLTF_LOADED })
      resolve(gltf)
    },
    xhr => {
      if (xhr.lengthComputable) {
        store.dispatch({
          type: constants.GLTF_PROGRESS,
          data: xhr.loaded / xhr.total,
        })
      }
    },
    error => {
      console.error('Error loading GLTF scene', error)
      store.dispatch({ type: constants.GLTF_ERROR })
      reject(error)
    },
  )
})

export default loader
