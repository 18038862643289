import React from 'react'

import './Spinner.scss'

const Spinner = () => (
  <div className="Spinner">
    <svg viewBox="0 0 112 112">
      <path
        d="M65 5.5l6.3 10.8C49.1 8 24.4 19 16 41.1S18.6 88 40.7 96.4c21.4 8.2 45.5-2 54.6-23.1l-2.8-1.2c-8.7 20.2-32.1 29.5-52.3 20.8S10.7 60.8 19.4 40.6c8.5-19.7 31-29.1 51-21.3L59 24.7l1.3 2.7 16.3-7.8-9-15.6L65 5.5zm7.4 12.9z"
        fill="#f8daea"
      />
    </svg>
  </div>
)

export default Spinner
