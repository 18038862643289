import React from 'react'
import PropTypes from 'prop-types'
import Typewriter from 'typewriter-effect'

import './Quotes.scss'

const Quotes = ({ instruction }) => {
  const { quote1, quote2, quote3 } = instruction
  const quotes = [quote1, quote2, quote3].filter(q => q.trim().length > 0)

  return (
    <div className="QuotesContainer">
      <Typewriter
        options={{
          strings: quotes,
          autoStart: true,
          loop: true,
        }}
      />
    </div>
  )
}

Quotes.propTypes = {
  instruction: PropTypes.shape({
    quote1: PropTypes.string,
    quote2: PropTypes.string,
    quote3: PropTypes.string,
  }).isRequired,
}

export default Quotes
