import React, { Component } from 'react'
import PropTypes from 'prop-types'
import copy from 'copy-to-clipboard'
import ReactGA from 'react-ga'

import './ShareBar.scss'
import BottomBar from '../BottomBar/BottomBar'
import supportsWebShare from '../../utils/supportsWebShare'
import ShareButton from '../Buttons/ShareButton'

class ShareBar extends Component {
  state = {
    copied: false,
    isNative: supportsWebShare(),
  }

  componentDidUpdate(prevProps, prevState) {
    const { copied } = this.state
    if (copied && copied !== prevState.copied) {
      this.timeout = setTimeout(() => {
        this.setState({
          copied: false,
        })
      }, 5000)
    }
  }

  componentWillUnmount() {
    if (this.timeout) clearTimeout(this.timeout)
  }

  render() {
    const { background, shareNatively } = this.props
    return this.state.copied ? (
      <BottomBar className="ShareBar" textId="share.copied" background />
    ) : (
      <BottomBar
        className="ShareBar"
        textId="share.bar.version"
        background={background}
        action={() => {
          if (this.state.isNative) {
            shareNatively()
          } else {
            copy(window.location.href)
            this.setState({ copied: true })
            ReactGA.event({
              category: 'share',
              action: 'copied link',
            })
          }
        }}
        icon={ShareButton}
      />
    )
  }
}

ShareBar.propTypes = {
  background: PropTypes.bool,
  shareNatively: PropTypes.func.isRequired,
}

ShareBar.defaultProps = {
  background: true,
}

export default ShareBar
